import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import GlobalDataManager from '../GlobalDataManager.js';
import { timeSince } from '../Utils.js';

import Spinner from '../components/Spinner.js';
import Panel from '../components/Panel.js';
import Card from '../components/Card.js';
import Pill from '../components/Pill.js';
import ModalWrap from '../components/ModalWrap.js';
import CopyToClipboard from '../components/CopyToClipboard.js';
import PageHeader from '../components/PageHeader.js';
import TemplatePanel from '../components/TemplatePanel.js';
import TemplateSlider from '../components/TemplateSlider.js';

import { useGetTemplatesV2 } from '../clients/TemplatesClient.js';
import { useGetMailingsV2 } from '../clients/MailingsClient.js';
import { useGetWebhooksV2 } from '../clients/WebhooksClient.js';

import { content } from './Dashboard.content.js';

const Dashboard = () => {
  const { isAuthenticated } = useAuth0();

  const [_modalShow, setModalShow] = useState(false);
  const [_modalContent, setModalContent] = useState('Loading...');

  const userData = GlobalDataManager.getInstance().getUserData();

  const mailingsRequest = useGetMailingsV2(1, 4);
  const templatesRequest = useGetTemplatesV2(1, 3, '', true);
  const webhooksResponse = useGetWebhooksV2(1, 5);

  // not in API

  const onSelectTemplate = (templateId) => {
    const items = templatesRequest.data.data.filter((t) => t.id === templateId);
    if (items.length === 0) {
      return;
    }
    const item = items[0];
    setModalContent(
      <>
        <TemplateSlider {...item} />
        <div className='btn-wrap text-center'>
          <Link to={'/create-mailing/' + templateId} className='btn btn-solid'>
            {content.Templates.UseThisTemplateText}
          </Link>
        </div>
      </>
    );
    setModalShow(true);
  };

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--dashboard'>
        <div className='gp-section__inner'>
          {isAuthenticated && (
            <div className='flex flex--wrap'>
              <div className='col-6'>
                <Panel>
                  <h2>
                    Hi, <span className='user-first'>{isAuthenticated && userData.firstName}</span>
                  </h2>
                  <p>{content.Greeting.SubTitle}</p>
                </Panel>
                <Card className='card--your-mailings' title={content.Mailings.Title} ctaURL='/mailings' ctaLabel={content.Mailings.Button}>
                  {!mailingsRequest.loading &&
                    mailingsRequest.success &&
                    (mailingsRequest.data.data.length > 0 ? (
                      <table className='tbl-mailing has-border'>
                        <thead>
                          <tr>
                            <th>{content.Mailings.Table.Column0}</th>
                            <th className='hide-mobile'>{content.Mailings.Table.Column1}</th>
                            <th className='text-center'>{content.Mailings.Table.Column2}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {mailingsRequest.data.data.map((item) => {
                            const d_created = Date.parse(item.created);
                            return (
                              <tr key={item.id}>
                                <td>{item.name}</td>
                                <td className='hide-mobile'>{timeSince(d_created)}</td>
                                <td className='text-center'>
                                  <Pill status={item.status} />
                                </td>
                                <td className='text-right'>
                                  <Link to={'/mailing/' + item.id}>
                                    View <i className='icon-angle-right'></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p className='no-results text-center'>{content.Mailings.Empty}</p>
                    ))}
                  {mailingsRequest.loading && <Spinner />}
                  {mailingsRequest.error !== null && <p className='alert-msg'>{content.Mailings.Error}</p>}
                </Card>
              </div>
              <div className='col-6'>
                <Card className='card--getting-started stretch' title='Getting Started'>
                  <div className='help-rows'>
                    {content.HelpArticles.map((item, i) => {
                      return (
                        <div key={item.id} className='help-row'>
                          <div className='flex'>
                            <div className='col-icon'>
                              <div className='icon'>
                                <i className={item.icon}></i>
                              </div>
                            </div>
                            <div className='col'>
                              <div className='help-row__title'>{item.title}</div>
                              <div className='help-row__excerpt'>{item.excerpt}</div>
                              <a href={item.url} className='help-row__link' target='_blank'>
                                Learn how <i className='icon-angle-right'></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </div>
              <div className='col-6'>
                <Card
                  className='card--your-templates stretch'
                  title={content.Templates.Title}
                  ctaURL='/templates'
                  ctaLabel={content.Templates.Button}
                >
                  <div className='templates'>
                    {!templatesRequest.loading &&
                      templatesRequest.success &&
                      (templatesRequest.data.data.length > 0 ? (
                        templatesRequest.data.data.map((item, i) => {
                          return <TemplatePanel key={item.id} {...item} onClick={onSelectTemplate} />;
                        })
                      ) : (
                        <p className='no-results text-center'>{content.Templates.Empty}</p>
                      ))}
                  </div>
                  {templatesRequest.loading && <Spinner />}
                  {templatesRequest.error !== null && <p className='alert-msg'>{content.Templates.Error}</p>}
                </Card>
              </div>
              {userData.subscription === 'Advanced' && (
                <div className='col-6'>
                  <Card
                    className='card--your-integrations stretch'
                    title={content.Webhooks.Title}
                    ctaURL='/webhooks'
                    ctaLabel={content.Webhooks.Button}
                  >
                    <div className='webhooks'>
                      {webhooksResponse.success && (
                        webhooksResponse.data.data.length === 0 ? (<p className='no-results text-center'>{content.Webhooks.Empty}</p>) : (
                          <table className='tbl-mailings has-border'>
                          <thead>
                            <tr>
                              <th>{content.Webhooks.Table.Column0}</th>
                              <th>{content.Webhooks.Table.Column1}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {webhooksResponse.data.data.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>
                                    <span className='wh-url'>{item.url}</span>
                                    <CopyToClipboard url={item.url} />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        )
                      )}
                      {webhooksResponse.loading && <Spinner />}
                      {webhooksResponse.error !== null && <p className='alert-msg'>{content.Webhooks.Error}</p>}
                    </div>
                  </Card>
                </div>
              )}
            </div>
          )}
        </div>
      </section>

      <ModalWrap heading='Template' show={_modalShow} onHide={() => setModalShow(false)}>
        {_modalContent}
      </ModalWrap>
    </>
  );
};

export default Dashboard;
