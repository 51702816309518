const formats = [
	{
		id: 'letter',
		name: 'Letter',
	  },
	  {
		id: 'postcard',
		name: 'Postcard'
	  }
];

if(process.env.REACT_APP_TOGGLES_ENABLE_GREETINGCARDS === 'true'){
	formats.push({
		id: 'greetingcard',
		name: 'Greeting Card'
	})
}


export const config = {
	Auth0: {
		Domain: process.env.REACT_APP_AUTH0_DOMAIN,
		ClientId: process.env.REACT_APP_AUTH0_CLIENTID,
		RedirectUri: window.location.origin,
		Audience: process.env.REACT_APP_AUTH0_AUDIENCE
	},
	Templates: {
		BaseUri: process.env.REACT_APP_TEMPLATES_BASEURI
	},
	Mailings: {
		BaseUri: process.env.REACT_APP_MAILINGS_BASEURI
	},
	Accounts:{
		BaseUri: process.env.REACT_APP_ACCOUNTS_BASEURI
	},
	Wizard: {
		BaseUri: process.env.REACT_APP_WIZARD_BASEURI
	},
	Webhooks: {
		BaseUri: process.env.REACT_APP_WEBHOOKS_BASEURI
	},
	Formats: formats,
	Canva:{
		ButtonApiKey: process.env.REACT_APP_CANVA_BUTTON_APIKEY
	},
	Toggles:{
		EnablePdfs: process.env.REACT_APP_TOGGLES_ENABLE_PDFS === 'true',
		EnableTemplateEditor: process.env.REACT_APP_TOGGLES_ENABLE_TEMPLATEEDITOR === 'true'
	}
};
