export default class GlobalDataManager {

  static myInstance = null;

  _userData = {
		username: '',
		firstName: '',
		lastName: '',
		email: ''
	};

  static getInstance() {
    if (GlobalDataManager.myInstance == null) {
      GlobalDataManager.myInstance = new GlobalDataManager();
    }
    return this.myInstance;
  }

  getUserData() {
    return this._userData;
  }

  setUserData(data) {
    this._userData = data;
  }
}
