import { useState } from 'react';
import { copyToClipboard } from '../Utils.js';

const CopyToClipboard = ({url}) => {

  const [_clicked, setClicked] = useState(false);

  const handleClick = () => {
    copyToClipboard(url);
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    },2000)
  }

  const copiedClass = (_clicked) ? ' is-copied' : '';

  return (
		<button className={'btn btn-copytoclipboard'+copiedClass} onClick={handleClick}>
			{ _clicked ? 'Copied' : 'Copy URL' }
		</button>
  );
}
export default CopyToClipboard;
