export const content = {
  Heading: 'Create a template',
  SampleDownloads: {
    letter: '/templates/postalzoom-sample-letter.docx',
    postcard: '/templates/postalzoom-sample-postcard.docx',
    default: '/templates/postalzoom-sample-letter.docx'
  },
  ConfirmUploadedHeading: 'Template Uploaded!',
  ConfirmUploadedText: <p></p>,
  HelpArticles: [
    {
      id: '1',
      title: 'Creating a template',
      excerpt: 'Get help with creating a new template.',
      icon: 'icon-template',
      url: 'https://docs.postalzoom.com/portal/en/kb/templates'
    }
  ]
}
