import { useGetProofV2 } from '../clients/MailingsClient.js';
import Spinner from '../components/Spinner.js';

const ProofPanel = ({ id, pageIndex, onClick, colClass = 'col-20' }) => {
  const proofResponse = useGetProofV2(id, pageIndex);

    const handleClick = (id) => {
      if (onClick) {
        onClick(id)
      }
    }

    if (proofResponse.error) {
      return (
        <div className='pending-preview'>
          <div className='pending-preview__inner'>
            <div className='image'></div>
            <div className='msg'>
              Your preview is being created.
              <br />
              Check back soon.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'template-panel ' + colClass}>
        <button className='template-panel__inner' onClick={() => handleClick(id)}>
        <div className='template-icon'>
          <div className='image-wrap'>
            <div className='image'>
              { proofResponse.success && <img src={URL.createObjectURL(proofResponse.data)} width="100%" /> }
              { proofResponse.loading && <Spinner /> }
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};
export default ProofPanel;
