const Pill = ({ status }) => {
	let pillStyle = '';

	switch (status) {
		case 'Awaiting approval':
			pillStyle = ' pending';
			break;
		case 'Approved':
			pillStyle = ' complete';
			break;
		case 'Sent':
			pillStyle = ' success';
			break;
		default:
	}
		console.log('pill', status, pillStyle);

  return (
    <span className={'pill' + pillStyle}>{status}</span>
  );
}
export default Pill;
