import { useEffect, useState, useContext } from 'react';
import { Navigate, useParams, Link } from 'react-router-dom';
import { dateTime, currencyFormat } from '../Utils.js';
import PageHeader from '../components/PageHeader.js';
import Panel from '../components/Panel.js';
import Card from '../components/Card.js';
import Alert from '../components/Alert.js';
import {
  useGetAddressesV2,
  useDeleteMailingV2,
  useDeleteAddressV2,
  useGetPricesV2,
  useGetMailingV2,
  useApproveMailingV2,
} from '../clients/MailingsClient.js';
import {
  useManageAccountV2,
  useAddPaymentMethodV2
} from '../clients/AccountsClient.js';
import Spinner from '../components/Spinner.js';
import ProofSlider from '../components/ProofSlider.js';
import ModalWrap from '../components/ModalWrap.js';
import EditMailingName from '../components/EditMailingName.js';
import { content } from './Mailing.content.js';

import mainContext from '../context/context.js';

const Mailing = () => {
  const { notification } = useContext(mainContext);

  const { mailingId } = useParams();

  const [manageAccount, setManageAccount] = useState(false);
  const manageAccountRedirect = useManageAccountV2(manageAccount);
  useEffect(() => {
    if (manageAccountRedirect.success) {
      window.location.href = manageAccountRedirect.data;
    }
  }, [manageAccountRedirect]);


  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const addPaymentMethodRedirect = useAddPaymentMethodV2(addPaymentMethod);
  useEffect(() => {
    if (addPaymentMethodRedirect.success) {
      window.location.href = addPaymentMethodRedirect.data;
    }
  }, [addPaymentMethodRedirect]);


  const [_modalConfirmShow, setModalConfirmShow] = useState(false);
  const [_doDelete, setDoDelete] = useState(false);
  const [_doRedirect, setRedirect] = useState(false);
  const [sendApproval, setSendApproval] = useState(false);

  const deleteMailingResponse = useDeleteMailingV2(mailingId, _doDelete);

  const addressesResponse = useGetAddressesV2(mailingId, 1, 1000);
  const [_deletedAddresses, setDeletedAddresses] = useState([]);
  const pricesResponse = useGetPricesV2(mailingId);
  const mailingResponse = useGetMailingV2(mailingId);
  const approvalResponse = useApproveMailingV2(mailingId, sendApproval);

  const [_selectedAddress, setSelectedAddress] = useState(null);
  const [_doDeleteAddress, setDoDeleteAddress] = useState(false);
  const [_modalConfirmAddShow, setModalConfirmAddShow] = useState(false);

  const deleteAddressResponse = useDeleteAddressV2(mailingId, _selectedAddress, _doDeleteAddress);

  const ApprovalButton = (
    <button onClick={() => setSendApproval(true)} className='btn btn-solid'>
      Approve for sending
    </button>
  );

  const AddCardButton = (
    /*
    <button onClick={() => setAddPaymentMethod(true)} className='btn btn-solid'>
      Add Card
    </button>
    */
    <Link to="/manage-account" className='btn btn-solid'>
      Add Card
    </Link>
  );

  const ViewCardButton = (
    /*
    <button onClick={() => setAddPaymentMethod(true)} className='btn btn-solid'>
      View Card Details
    </button>
    */
    <Link to="/manage-account" className='btn btn-solid'>
      View Card Details
    </Link>
  );

  const onConfirmDelete = (e, conf) => {
    if (!conf) {
      setModalConfirmShow(false);
    } else {
      setDoDelete(true);
    }
  };

  useEffect(() => {
    if (deleteMailingResponse.success) {
      // show confirmation message
      notification({ message: content.NotificationDeleted, type: 'positive' });

      // redirect to mailings page
      setRedirect(true);
      setModalConfirmShow(false);
    }

    setDoDelete(false);
  }, [deleteMailingResponse]);

  const onDeleteAddress = (row) => {
    setSelectedAddress(row);
    setModalConfirmAddShow(true);
  }

  const onConfirmAddDelete = (e, conf) => {
    if (!conf) {
      setModalConfirmAddShow(false);
    } else {
      setDoDeleteAddress(true);
    }
  };

  useEffect(() => {
    if (deleteAddressResponse.success) {
      // show confirmation message
      notification({ message: content.NotificationAddDeleted, type: 'positive' });

      setDeletedAddresses([..._deletedAddresses, _selectedAddress.batchId]);
      setSelectedAddress(null);
      setModalConfirmAddShow(false);
    }

    setDoDeleteAddress(false);

  }, [deleteAddressResponse]);


  if (_doRedirect) {
    return <Navigate to={'/mailings'} />;
  }

  const renderBasicStatusAlert = (status) => {
    switch (status) {
      case 'Created':
        return (
          <Alert title='We are processing your mailing' style='positive'>
            <p>Our team are busy preparing your mailing. Once we have an update we will be in touch. </p>
          </Alert>
        );

      case 'Approved':
        return (
          <Alert title='Approved' style='success'>
            <p>Your mailing has been approved and will be sent shortly</p>
          </Alert>
        );

      case 'Sent':
        return (
          <Alert title='Sent!' style='success'>
            <p>Great news! Your mailing has been sent.</p>
          </Alert>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--mailing-single'>
        <div className='gp-section__inner'>
          <div className='gp-section__heading'>
            <div className='flex'>
              <div className='col'>
                {mailingResponse.success ?
                  <EditMailingName
                    id={mailingId}
                    name={mailingResponse.data.name}
                    label="title"
                  /> : ''}
              </div>
              <div className='col text-right'>
                {mailingResponse.success && mailingResponse.data.createdBy === 'wizard' && mailingResponse.data.status === 'Created' && (
                  <button onClick={() => setModalConfirmShow(true)} className='btn btn-delete'>
                    {content.DeleteThisMailingText}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className='flex flex--wrap'>
            <div className='col-6'>
              <Panel>
                {mailingResponse.loading && <Spinner />}
                {mailingResponse.error !== null && (
                  <Alert style='error'>
                    <p className='alert-msg'>There was a problem loading the mailing</p>
                  </Alert>
                )}
                {mailingResponse.success &&
                  (mailingResponse.data.status !== 'Awaiting approval' ? (
                    renderBasicStatusAlert(mailingResponse.data.status)
                  ) : sendApproval ? (
                    <>
                      {approvalResponse.loading && <Spinner />}
                      {approvalResponse.success && approvalResponse.status === 200 && (
                        <Alert title='Approved' style='success'>
                          <p>Your mailing has been approved and will be sent shortly</p>
                        </Alert>
                      )}
                      {approvalResponse.error !== null && approvalResponse.status === 420 && (
                        <Alert title='Oops!' style='error' actions={AddCardButton}>
                          <p>No card found.</p>
                        </Alert>
                      )}
                      {approvalResponse.error !== null && approvalResponse.status === 421 && (
                        <Alert title='Oops!' style='error' actions={ViewCardButton}>
                          <p>Your payment has been declined. Please check your card details and try again.</p>
                        </Alert>
                      )}
                      {
                        approvalResponse.error !== null && approvalResponse.status !== 420 && approvalResponse.status !== 421 && (
                          <Alert title='Something went wrong' style='error'>
                            <p>There was a problem approving the mailing</p>
                            <p>Please try again later</p>
                          </Alert>
                        )}
                    </>
                  ) : (
                    <Alert title='Awaiting your approval' actions={ApprovalButton}>
                      <p>You mailing is ready to be sent. Please check the details below.</p>
                    </Alert>
                  ))}
              </Panel>
              <Card className='card--mailing-details' title='Mailing details'>
                <div className='flex flex--wrap'>
                  <div className='col-12'>
                    <h4>Summary</h4>
                    <hr />
                    {mailingResponse.loading && <Spinner />}
                    {mailingResponse.error !== null && <p className='alert-msg'>There was a problem loading the mailing</p>}
                    {mailingResponse.success && (
                      <table className='tbl-mailings'>
                        <tbody>
                          <tr>
                            <td>Format</td>
                            <td className='text-right'>{mailingResponse.data.format}</td>
                          </tr>
                          <tr>
                            <td>Colour</td>
                            <td className='text-right'>{mailingResponse.data.colour}</td>
                          </tr>
                          {
                            mailingResponse.data.format.toLowerCase() === 'letter' &&
                            <>
                              <tr>
                                <td>Sides</td>
                                <td className='text-right'>{mailingResponse.data.isDuplex ? "Double sided" : "Single sided"}</td>
                              </tr>
                            </>
                          }
                          <tr>
                            <td>Pages</td>
                            <td className='text-right'>{mailingResponse.data.pages}</td>
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td className='text-right'>{mailingResponse.data.quantity}</td>
                          </tr>
                          <tr>
                            <td>Date created</td>
                            <td className='text-right'>{mailingResponse.data.created}</td>
                          </tr>
                          {mailingResponse.data.approvedDate !== null && (
                            <tr>
                              <td>Date approved</td>
                              <td className='text-right'>{mailingResponse.data.approvedDate}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className='col-12'>
                    <h4>Payment details</h4>
                    <hr />

                    {pricesResponse.loading && <Spinner />}
                    {pricesResponse.error !== null && <p className='alert-msg'>Unable to load price data</p>}
                    {pricesResponse.success && (
                      <table className='tbl-mailings'>
                        <tbody>
                          <tr>
                            <td>Items</td>
                            <td className='text-right'>{currencyFormat(pricesResponse.data.items)}</td>
                          </tr>
                          <tr>
                            <td>VAT Added (20%)</td>
                            <td className='text-right'>{currencyFormat(pricesResponse.data.vat)}</td>
                          </tr>
                          <tr>
                            <td>Total:</td>
                            <td className='text-right'>{currencyFormat(pricesResponse.data.total)}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div className='col-6'>
              <Card className='card--mailing-preview stretch' title='Preview'>
                {mailingResponse.success && <ProofSlider id={mailingResponse.data.id} pageCount={mailingResponse.data.pages} />}
              </Card>
            </div>
            <div className='col-12'>
              <Card className='stretch' title='Mailing data'>
                <div className='table-responsive'>
                  <div className='table has-border'>
                    <div className='tr'>
                      {addressesResponse.success &&
                        addressesResponse.data.headings.map((item) => {
                          return (
                            <div key={item.id} className='th'>
                              {item.displayName}
                            </div>
                          );
                        })}

                      {mailingResponse.data.createdBy === 'webhook' && mailingResponse.data.status !== 'Sent' ? <div className='th'>Created</div> : null}

                      {mailingResponse.data.createdBy === 'webhook' ? <div className='th'>

                      </div> : null}
                    </div>
                    {addressesResponse.success &&
                      addressesResponse.data.rows.map((row) => {
                        if (_deletedAddresses.includes(row.batchId)) {
                          return false;
                        }

                        const d_created = Date.parse(row.created);

                        return (
                          <div key={row.row + row.batchId} className='tr'>
                            {row.columns.map((column) => {
                              return (
                                <div key={column.id} className='td'>
                                  {column.value}
                                </div>
                              );
                            })}

                            {mailingResponse.data.createdBy === 'webhook' ? <div className='td'>{dateTime(d_created)}</div> : null}

                            {mailingResponse.data.createdBy === 'webhook' && mailingResponse.data.status !== 'Sent' ? <div className='td'><button
                              className='btn btn-outline btn-action'
                              onClick={() => {
                                onDeleteAddress(row);
                              }}
                            >
                              <i className='icon-trash-empty'></i>
                              <span className='visually-hidden'>Delete mailing</span>
                            </button>
                            </div> : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <ModalWrap heading={content.ConfirmDeleteHeading} show={_modalConfirmShow} onHide={() => setModalConfirmShow(false)}>
        {content.ConfirmDeleteText}
        {deleteMailingResponse.error !== null && <p className='alert-msg'>There was a problem deleting this mailing</p>}
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={(e) => onConfirmDelete(e, false)}>
            Cancel
          </button>
          <button className={deleteMailingResponse.loading ? 'btn btn-solid btn-delete is-spinning' : 'btn btn-solid btn-delete'} onClick={(e) => onConfirmDelete(e, true)}>
            Delete
          </button>
        </div>
      </ModalWrap>

      <ModalWrap heading={content.ConfirmDeleteHeading} show={_modalConfirmAddShow} onHide={() => setModalConfirmAddShow(false)}>
        {content.ConfirmDeleteAddText}
        {deleteAddressResponse.error !== null && <p className='alert-msg'>There was a problem deleting this address.</p>}
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={(e) => onConfirmAddDelete(e, false)}>
            Cancel
          </button>
          <button className={deleteAddressResponse.loading ? 'btn btn-solid btn-delete is-spinning' : 'btn btn-solid btn-delete'} onClick={(e) => onConfirmAddDelete(e, true)}>
            Delete
          </button>
        </div>
      </ModalWrap>
    </>
  );
};

export default Mailing;
