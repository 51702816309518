const Footer = () => {
  const year = new Date().getFullYear()
    return (
      <footer className="main_footer">
        <div className="main_footer__inner">
          <div className="copyright">&copy; Copyright {year} Postalzoom</div>
          <nav className="legal-links">
            <a target='_blank' href="https://www.postalzoom.com/privacy-policy" rel="noreferrer">Privacy Policy</a>
            <span className="separator">|</span>
            <a target='_blank' href="https://www.postalzoom.com/terms-of-use" rel="noreferrer">Terms &amp; Conditions</a>
          </nav>
        </div>
      </footer>
    );
}
export default Footer;
