import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader.js';
import Spinner from '../components/Spinner.js';
import Card from '../components/Card.js';
import CardStep from '../components/CardStep.js';
import OptionsPaperSize from '../components/OptionsPaperSize.js';
import DropZone from '../components/DropZone.js';
import ModalWrap from '../components/ModalWrap.js';
import EditName from '../components/EditName.js';
import { useUploadTemplateV2, useNameCheckV2 } from '../clients/TemplatesClient.js';
import { config } from '../Config.js';

import { content } from './TemplateUploaderFormat.content.js';

const TemplateUploader = () => {
  const navigate = useNavigate();

  const formats = config.Formats;
  const [_errors, setErrors] = useState({});

  const [_currentStep, setCurrentStep] = useState(1);
  const [_isComplete, setIsComplete] = useState(false);
  const _numSteps = 3;

  const [_templateName, setTemplateName] = useState('Untitled');
  const [_templateSize, setTemplateSize] = useState(false);
  const [_isNamed, setIsNamed] = useState(false);
  const [_isNamedError, setIsNamedError] = useState(false);

  const [_isNameTakenError, setIsNameTakenError] = useState(false);
  const [_doCheckName, setDoCheckName] = useState(false);

  const [_isFileSelected, setIsFileSelected] = useState(false);

  const [_color, setColor] = useState(false);

  const [file, setFile] = useState(null);
  const uploadResponse = useUploadTemplateV2(file, _templateName, _templateSize.id);
  const nameCheckResponse = useNameCheckV2(_templateName, _doCheckName);

  const prevStep = (step) => {
    if (_isComplete) {
      return;
    }
    if (step === 1) {
      navigate('/templates');
    } else if (step - 1 > 0) {
      setCurrentStep(step - 1);
    }
  };

  const nextStep = (step) => {
    if (_isComplete || !_isNamed) {
      return;
    }

    let errors = {};

    if (step === 1 && !_templateSize) {
      errors.error_size = {
        message: 'Please choose a size',
      };
      setErrors(errors);
      return;
    }
    if (step === 2 && !_isFileSelected) {
      errors.error_no_file = {
        message: 'No file selected',
      };
      setErrors(errors);
      return;
    }

    if (_numSteps === step) {
      setIsComplete(setIsComplete);
    } else if (step + 1 <= _numSteps) {
      setCurrentStep(step + 1);
    }
  };

  useEffect(() => {
    // reset if error
    if (uploadResponse.error) {
      setFile(null);
      setIsFileSelected(false);
    }
    if (!uploadResponse.success) {
      return;
    }
    nextStep(_currentStep);
  }, [_currentStep, uploadResponse]);

  const doReset = () => {
    // reset vars
    setCurrentStep(1);
    setIsComplete(false);
    setColor(false);
    setFile(null);
    setIsFileSelected(false);
    setIsNamed(false);
    setIsNamedError(false);
    setTemplateName('Untitled');
    setTemplateSize(false);
    setErrors({});
  };

  /**
   * Event handlers
   */

  const onNameChange = (ev) => {
    if (ev.target.name !== '') {
      setDoCheckName(true);
    }
    setTemplateName(ev.target.value);
  };

  const onKeyPress = (ev) => {
    if (ev.charCode === 13) {
      if (_templateName !== '' && _templateName !== 'Untitled') {
        setIsNamed(true);
      }
    }
  };

  const onNameClosed = () => {
    if (_templateName === '') {
      setTemplateName('Untitled');
    }
    setIsNamed(true);
  };

  useEffect(() => {
    if (nameCheckResponse.status === 400) {
      setIsNameTakenError(true);
    }
    else {
      setIsNameTakenError(false);
    }
    setDoCheckName(false);
  }, [nameCheckResponse]);

  const onNameChosen = () => {
    if (!nameCheckResponse.error) {
      return;
    }
    if (_templateName !== '' && _templateName !== 'Untitled') {
      setIsNamed(true);
      setIsNamedError(false);

      let errors = _errors;
      errors.error_name = false;
      setErrors(errors);
    } else {
      setIsNamedError(true);
    }
  };

  const onNameUpdated = (name) => {
    setTemplateName(name);
  };

  const onFileSelected = (file) => {
    if (file === undefined) {
      return;
    } else {
      let errors = _errors;
      errors.error_no_file = false;
      setErrors(errors);
      setIsFileSelected(true);
      setFile(file);
    }
  };

  const onSizeSelect = (size) => {
    setTemplateSize(size);

    if (_errors.error_size) {
      let errors = _errors;
      errors.error_size = false;
      setErrors(errors);
    }
  };

  const getSampleDownload = () => {

    switch (_templateSize.id) {
      case 'letter':
        return content.SampleDownloads.letter;
      case 'postcard':
        return content.SampleDownloads.postcard;
      default:
        return content.SampleDownloads.default;
    }
  }

  return (
    <>
      <PageHeader pageTitle={content.Heading} />

      <section className='gp-section gp-section--ct-wizard'>
        <div className='gp-section__inner'>
          <div className='gp-section__heading'>
            {file ? <h1>{_templateName}</h1> : <EditName name={_templateName} callback={onNameUpdated} />}
            {_isNameTakenError ? <div className='error-msg'>Name taken. Please choose another.</div> : null}
          </div>

          <div className='flex'>
            <div className='col-6'>
              <CardStep
                stepNum={1}
                numSteps={_numSteps}
                currentStep={_currentStep}
                onPrev={prevStep}
                onPrevLbl='Back to templates'
                onNext={nextStep}
                onNextLbl='Next'
                title={_templateSize && _currentStep > 1 ? 'Choose a format: ' + _templateSize.name : 'Choose a format'}
              >
                <OptionsPaperSize sizes={formats} paperSize={_templateSize} onSizeSelect={onSizeSelect} />
                {_errors.error_size && <p className='error-msg'>{_errors.error_size.message}</p>}
              </CardStep>

              <CardStep
                stepNum={2}
                numSteps={_numSteps}
                currentStep={_currentStep}
                onPrev={prevStep}
                onPrevLbl='Back'
                title='Upload your template'
              >
                {uploadResponse.loading ? (
                  <Spinner />
                ) : (
                  <>
                    {config.Toggles.EnablePdfs && (
                      <p className='text-center'>The file you upload must be a valid .pdf, .doc or .docx file.</p>
                    )}
                    {!config.Toggles.EnablePdfs && <p className='text-center'>The file you upload must be a valid .doc or .docx file.</p>}
                    <p className='text-center'>
                      Download a{' '}
                      <a href={getSampleDownload()} download>
                        sample template
                      </a>{' '}
                      to see an example.
                    </p>
                    {config.Toggles.EnablePdfs && <DropZone acceptedTypes='.doc, .docx, .pdf' callback={onFileSelected} />}
                    {!config.Toggles.EnablePdfs && <DropZone acceptedTypes='.doc, .docx' callback={onFileSelected} />}
                  </>
                )}

                {uploadResponse.error !== null &&
                  (uploadResponse.error.message == null ? (
                    <p className='error-msg text-center'>There was a problem talking to the server</p>
                  ) : (
                    <>
                      <p className='error-msg text-center'>{uploadResponse.error.message}</p>
                      {uploadResponse.error.errors != null &&

                        uploadResponse.error.errors.map((error, i) => {
                          return (<>
                            <p className='error-msg text-center'>
                              <span key={i}>
                                {error}
                                <br />
                              </span>
                            </p>
                          </>
                          );
                        })
                      }
                    </>
                  ))}
              </CardStep>

              <CardStep
                stepNum={3}
                numSteps={_numSteps}
                currentStep={_currentStep}
                onPrev={false}
                onNext={false}
                title=''
                stepClass='card--template-uploaded'
              >
                <div className='content text-center'>
                  <div className='icon-success'></div>
                  <div className='heading'>{content.ConfirmUploadedHeading}</div>
                  <div className='copy'>
                    {content.ConfirmUploadedText}
                  </div>
                  <div className='btn-wrap'>
                    <button className='btn btn-outline' onClick={doReset}>
                      Upload another
                    </button>
                    {/* <Link to={'/create-mailing/' + templateId} className='btn btn-solid'>
                      Use this template <i className='icon-plus'></i>
                    </Link> */}
                  </div>
                </div>
              </CardStep>

              <Card className='card--getting-started'>
                <div className='help-rows'>
                  {content.HelpArticles.map((item, i) => {
                    return (
                      <div key={item.id} className='help-row'>
                        <div className='flex'>
                          <div className='col-icon'>
                            <div className='icon'>
                              <i className={item.icon}></i>
                            </div>
                          </div>
                          <div className='col'>
                            <div className='help-row__title'>{item.title}</div>
                            <div className='help-row__excerpt'>{item.excerpt}</div>
                            <a href={item.url} className='help-row__link' target='_blank'>
                              Learn how <i className='icon-angle-right'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <ModalWrap heading='Choose a name' show={!_isNamed} onHide={() => onNameClosed()}>
        <div className='form-row'>
          <input
            type='text'
            id='input-template-name'
            placeholder='Enter template name'
            className={_isNamedError ? 'error' : ''}
            onChange={onNameChange}
            onKeyPress={onKeyPress}
          />
          {_isNameTakenError ? <div className='error-msg'>Name taken. Please choose another.</div> : null}
          {_isNamedError && <div className='error-msg'>Please choose a name</div>}
        </div>
        <div className='btn-wrap text-right'>
          <button className='btn btn-solid' disabled={_templateName === '' || _isNameTakenError} onClick={(e) => onNameChosen()}>
            Continue
          </button>
        </div>
      </ModalWrap>
    </>
  );
};

export default TemplateUploader;
