import { useState, useEffect } from 'react';

const LinesButton = ({active, onClick}) => {

  const [_isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const toggleSettings = ev => {
    ev.preventDefault();
    onClick();
  }

  const linesBtnClass = (_isActive) ?
    'lines-button x active nav-close' :
    'lines-button x';

  return (
    <button type="button" aria-label="Toggle Navigation" className={ linesBtnClass } onClick={ toggleSettings }>
      <span className="lines"></span>
    </button>
  );
}

export default LinesButton;
