import { useEffect, useState, useContext } from 'react';

import { useChangeTemplateNameV2 } from '../clients/TemplatesClient.js';

import mainContext from '../context/context.js';
import { content } from '../pages/Templates.content.js';


const EditTemplateName = ({ id, name, onSuccess, label = 'name' }) => {
  const { notification } = useContext(mainContext);

	const [_name, setName] = useState(name);
	const [_newName, setNewName] = useState(null);
	const [_isEditing, setIsEditing] = useState(false);

  const nameChangeResponse = useChangeTemplateNameV2(id, _newName);

	const onEditMode = () => {
		setIsEditing(true);
	}

	const onChange = (ev) => {
		setName(ev.target.value);
	}

	const onSave = () => {
		// not changed
		if (name === _name) {
			setIsEditing(false);
		}
		// changed
		else {
			setNewName(_name);
		}
	}

  useEffect(() => {

    if (!nameChangeResponse.loading && nameChangeResponse.success) {
			// show json error message if set
			let json = null;
			try {
        json = JSON.parse(nameChangeResponse.data);
	    } catch (e) {}

			if (nameChangeResponse.data !== '' && json && json.Name) {
				notification({ message: json.Name, type: 'warning' });
			}
			else {
				notification({ message: content.NotificationUpdated, type: 'positive' });
				setIsEditing(false);
				if (onSuccess) {
					onSuccess(id, _name);
				}
			}
			setNewName(null);
		}
    if (!nameChangeResponse.loading && nameChangeResponse.error) {
			notification({ message: content.NotificationError, type: 'negative' });
			setNewName(null);
		}
  }, [nameChangeResponse]);

	useEffect(() => {
		setName(name);
	}, [name]);

	if (!_isEditing) {
		return (
			<div className="edit-name">
				<h2>{_name}</h2>
				<button className="link" onClick={onEditMode}>Edit {label}</button>
			</div>
		)
	}
	else {
		return (
			<div className="edit-name">
				<h2><input type="text" value={_name} onChange={onChange} disabled={nameChangeResponse.loading} /></h2>
				<button className={nameChangeResponse.loading ? 'link is-spinning' : 'link' } onClick={onSave} disabled={nameChangeResponse.loading}>Save {label}</button>
			</div>
		)
	}
}
export default EditTemplateName;
