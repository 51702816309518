import { Link } from 'react-router-dom';

const Card = ({className, title, children, ctaURL, action, button, ctaLabel = 'View', actionButtonLabel = 'Update'}) => {
  const header = (ctaURL || action|| button) ?
  <div className="flex">
    <div className="col-6">
      <span className="card-title">{title}</span>
    </div>
    <div className="col-6 text-right">
      {ctaURL && <Link className="btn btn-solid" to={ctaURL}>{ctaLabel}</Link>}
      {action && <button className="btn btn-outline" onClick={action} >{actionButtonLabel}</button>}
      {button}
    </div>
  </div> : <span className="card-title">{title}</span>;


  return (
    <div className={"card " + className}>
      <div className="card__inner">
        { (title || ctaURL || action) && (
          <div className="card-divider">
  					{header}
          </div>
        )}
        <div className="card-section">
					{children}
        </div>
      </div>
    </div>
  );
}
export default Card;
