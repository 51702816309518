import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import TemplatePanel from '../components/TemplatePanel.js';

// https://swiperjs.com/

const TemplateSlider = ({ id, pageCount = 1 }) => {
    let pages = [];
    for (var i = 1; i <= pageCount; i++) {
      pages.push({
        id: id,
        index: i
      });
    }
    return (
      <div className="template-slider">
        <div className="template-slider__inner">
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            className="slider"
          >
            {pages.map((item, i) => {
              return (
                <SwiperSlide key={item.index}>
                  <TemplatePanel
                    {...item}
                    pageIndex = {item.index}
                    colClass=""
                    />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    );
}
export default TemplateSlider;
