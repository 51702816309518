export const content = {
    Heading: 'Templates',
    Title: 'Template library',
    Filters:{
        Title: 'Size'
    },
    CreateLinkText: 'Create a new template',
    Error: 'Templates could not be loaded, please try again later.',
    ErrorDelete: 'There was a problem deleting your template. This may be because it is already being used.',
    ConfirmDeleteHeading: 'Confirm Delete',
    ConfirmDeleteText: <p>Are you sure you want to delete this template?</p>,
    deleteThisTemplateText: <><i className='icon-trash-empty'></i> Delete this template</>,
    UseThisTemplateText: <><i className='icon-plus'></i> Use this template</>,
    Notification:{
      Deleted: 'Template deleted',
      Updated: 'Template updated',
    },
    LoadMore: 'Load More',
}
