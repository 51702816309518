const colors = [
  {
    id: 'colour',
    name: 'Colour'
  },
  {
    id: 'black-and-white',
    name: 'Black & White'
  },
];

const OptionsColor = ({ color, onColorSelect }) => {
  return (
    <div className='options'>
      <div className='wrap'>
        {colors.map((item, i) => {
          const isSelected = color.id === item.id ? ' is-selected' : '';
          return (
            <div key={item.id} className={'item' + isSelected}>
              <button className='item__inner' onClick={() => onColorSelect(item)}>
                <div className='meta'>
                  <div className='name'>{item.name}</div>
                </div>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OptionsColor;
