import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner.js';
import PageHeader from '../components/PageHeader.js';
import TemplatePanel from '../components/TemplatePanel.js';
import TemplateSlider from '../components/TemplateSlider.js';
import ModalWrap from '../components/ModalWrap.js';
import EditTemplateName from '../components/EditTemplateName.js';
import { useDeleteTemplateV2, useGetTemplatesV2 } from '../clients/TemplatesClient.js';
import { content } from './Templates.content.js';

import mainContext from '../context/context.js';
import { config } from '../Config.js';

const Templates = () => {
  const { notification } = useContext(mainContext);

  const [_page, setPage] = useState(1);
  const perPage = (_page === 1) ? 11 : 12; // space for create new template btn

  const [_modalShow, setModalShow] = useState(false);
  const [_modalContent, setModalContent] = useState('Loading...');
  const [_filters, setFilters] = useState([]);

  const [_modalConfirmShow, setModalConfirmShow] = useState(false);
  const [_templateSelected, setTemplateSelected] = useState(null);
  const [_doDelete, setDoDelete] = useState(false);
  const [_deleteError, setDeleteError] = useState(null);
  const [_deletedRows, setDeletedRows] = useState([]);

  const [_loadedTemplates, setLoadedTemplates] = useState([]);

  const [_fetchTemplates, setFetchTemplates] = useState(true);
  const templatesResponse = useGetTemplatesV2(_page, perPage, _filters, _fetchTemplates);
  const deleteResponse = useDeleteTemplateV2(_templateSelected, _doDelete);

  const filteredTemplates = _loadedTemplates.filter((t) => {
    if (_deletedRows.filter((deletedId) => deletedId === t.id).length > 0) {
      return false;
    }

    // if (_filters.length === 0) {
    //   return true;
    // } else {
    //   for (var key in _filters) {
    //     if (t.format === _filters[key]) {
    //       return true;
    //     }
    //   }
    // }

    //return false;

    return true;
  });

  useEffect(() => {
    if (!templatesResponse.loading && templatesResponse.success) {
      let templates = _loadedTemplates;
      templatesResponse.data.data.forEach((item, i) => {
        if (templates.findIndex(x => x.id === item.id) === -1) {
          templates.push(item);
        }
      });
      setLoadedTemplates([...templates]);
      setFetchTemplates(false);
    }
  }, [templatesResponse]);

  const loadMore = () => {
    setPage(_page + 1);
  };

  const formats = config.Formats;

  const onTemplateNameUpdated = (templateId, name) => {
    const templates = _loadedTemplates.map((item, i) => {
      let itm = item;
      if (itm.id === templateId) {
        itm.name = name;
      }
      return itm;
    });
    setLoadedTemplates(templates);
  };

  const onSelectTemplate = (templateId) => {
    const items = _loadedTemplates.filter((t) => t.id === templateId);
    if (items.length === 0) {
      return;
    }

    setTemplateSelected(templateId);

    const item = items[0];
    setModalContent(
      <>
        <EditTemplateName id={templateId} name={item.name} label='title' onSuccess={onTemplateNameUpdated} />
        <TemplateSlider {...item} />
        <div className='btn-wrap text-center'>
          <Link to={'/create-mailing/' + templateId} className='btn btn-solid'>
            {content.UseThisTemplateText}
          </Link>
          <button className='btn btn-delete' onClick={onDeleteTemplate}>
            {content.deleteThisTemplateText}
          </button>
        </div>
      </>
    );
    setModalShow(true);
  };

  const onToggleFilter = (event) => {
    var filters = [..._filters];
    if (event.target.checked) {
      filters = [..._filters, event.target.value];
    } else {
      filters.splice(_filters.indexOf(event.target.value), 1);
    }
    setFilters(filters);

    // reset loaded templates
    setPage(1);
    setLoadedTemplates([]);
    setFetchTemplates(true);
  };

  const onClearFilters = () => {
    setFilters([]);
    document.querySelectorAll('input.template-filter').forEach((el) => (el.checked = false));

    setPage(1);
    setLoadedTemplates([]);
    setFetchTemplates(true);
  };

  const onDeleteTemplate = () => {
    setModalConfirmShow(true);
    setModalShow(false);
  };

  const onConfirmDelete = (e, conf) => {
    setDeleteError(null);
    if (!conf) {
      setModalShow(true);
      setModalConfirmShow(false);
    } else {
      setDoDelete(true);
    }
  };

  useEffect(() => {
    if (deleteResponse.success) {
      // show confirmation message
      notification({ message: content.Notification.Deleted, type: 'positive' });

      if (_templateSelected) {
        let arr = _deletedRows;
        arr.push(_templateSelected);
        setDeletedRows([...arr]);
      }

      setTemplateSelected(null);
      setModalConfirmShow(false);
    }

    if (deleteResponse.error !== null) {
      // display error
      setDeleteError(content.ErrorDelete);
    }

    // reset delete method
    setDoDelete(false);
  }, [deleteResponse]);

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--ct-templates'>
        <div className='gp-section__inner'>

          <div className='flex'>
            <div className='col-filters'>
              <h2>{content.Title}</h2>
              <h4>{content.Filters.Title}</h4>
              <hr />
              <div className='filters'>
                {formats.map((item, i) => {
                  return (
                    <div key={item.id} className='filter'>
                      <label htmlFor={'format-' + i}>
                        <span>{item.name}</span>{' '}
                        <input type='checkbox' className='template-filter' value={item.id} id={'format-' + i} onChange={onToggleFilter} />
                      </label>
                    </div>
                  );
                })}
                {_filters.length > 0 && (
                  <div className='link text-right'>
                    <button className='link' onClick={onClearFilters}>
                      Clear all
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className='col-main'>
              {
                <div className='templates'>
                  <div className={'template-panel template-panel--new col-20'}>
                    <Link to='/create-template' className='template-panel__inner'>
                      <div className='template-icon'>
                        <div className='image-wrap'>
                          <div className='plus'>
                            <i className='icon-plus-1'></i>
                          </div>
                        </div>
                      </div>
                      <div className='template-meta'>
                        <div className='template-name'>{content.CreateLinkText}</div>
                      </div>
                    </Link>
                  </div>
                  {filteredTemplates.map((item, _) => {
                    return <TemplatePanel key={item.id} {...item} onClick={onSelectTemplate} colClass='col-20' />;
                  })}
                </div>
              }
              {templatesResponse.loading && <Spinner />}
              {templatesResponse.error !== null && <p className='alert-msg text-center'>{content.Error}</p>}


              {templatesResponse.data.hasMorePages ? (
                <div className='btn-wrap btn-wrap--centered'>
                  <button
                    className={templatesResponse.loading ? 'btn btn-solid is-spinning' : 'btn btn-solid'}
                    onClick={loadMore}
                    disabled={templatesResponse.data.loading}
                  >
                    {content.LoadMore}
                  </button>
                </div>
              ) : null}
            </div>
          </div>

        </div>
      </section>

      <ModalWrap heading='Template' show={_modalShow} staticBG={true} onHide={() => setModalShow(false)}>
        {_modalContent}
      </ModalWrap>

      <ModalWrap heading={content.ConfirmDeleteHeading} show={_modalConfirmShow} onHide={(e) => onConfirmDelete(e, false)}>
        {content.ConfirmDeleteText}
        {_deleteError && <p className='alert-msg'>{_deleteError}</p>}
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={(e) => onConfirmDelete(e, false)}>
            Cancel
          </button>
          <button className='btn btn-solid btn-delete' onClick={(e) => onConfirmDelete(e, true)}>
            Delete
          </button>
        </div>
      </ModalWrap>
    </>
  );
};

export default Templates;
