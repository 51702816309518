import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ location, item, open, onSelected }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [itemClass, setItemClass] = useState('');

  let base_class = !item.submenu ? 'menu-item' : 'menu-item menu-item-has-children';

  useEffect(() => {
    let child_active = false;
    if (item.submenu) {
      item.submenu.forEach((sm_item, i) => {
        if (location.pathname === sm_item.path) {
          child_active = true;
        }
      });
    }
    let isActive = location.pathname === item.path || child_active ? ' active' : '';
    let isOpenClass = isOpen ? ' is-open' : '';

    setItemClass(base_class + isActive + isOpenClass);
  }, [isOpen, itemClass, location, open, base_class, item]);

  const toggleOpen = (ev) => {
    ev.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <li key={item.id} className={itemClass}>
      {!item.submenu && item.path.startsWith('/') && (
        <Link to={item.path} className='menu-item__lbl' onClick={onSelected}>
          <i className={item.icon}></i>
          {item.label}
        </Link>
      )}
      {!item.submenu && !item.path.startsWith('/') && (
        <a href={item.path} className='menu-item__lbl' target='_blank'>
          <i className={item.icon}></i>
          {item.label}
        </a>
      )}
      {item.submenu && (
        <>
          <button className='toggle-submenu' onClick={toggleOpen}>
            <div className='label'>
              <i className={item.icon}></i>
              {item.label}
            </div>
            <div className='toggle'>
              <i className='icon-angle-down'></i>
            </div>
          </button>
          <ul className='submenu'>
            {item.submenu.map((sm_item) => (
              <li key={sm_item.id} className={location.pathname === sm_item.path ? base_class + ' active' : base_class}>
                <Link to={sm_item.path} onClick={onSelected}>
                  {sm_item.label}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );
};
export default MenuItem;
