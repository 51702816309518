const OptionsFormat = ({ sizes, paperSize, onSizeSelect }) => {

  return (
    <div className="options-paper-size">
      <div className="options-paper-size__inner">
				<div className="paper-sizes">
					{
						sizes.map((item, i) => {
							const isSelected = (paperSize.id === item.id) ? ' is-selected' : '';
							return (
								<div key={item.id} className={"paper-size paper-size--" + item.id + isSelected}>
									<button className="paper-size__inner" onClick={() => onSizeSelect(item)}>
										<div className="icon">
											<div className="image"></div>
										</div>
										<div className="meta">
											<div className="name">{item.name}</div>
										</div>
									</button>
								</div>
							)
						})
					}
	      </div>
      </div>
    </div>
  );
}
export default OptionsFormat;
