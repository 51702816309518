import { useEffect, useState } from 'react';
import { currencyFormat } from '../Utils.js';
import { Link, useParams } from 'react-router-dom';

//https://react-select.com/home
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import Card from '../components/Card.js';
import CardStep from '../components/CardStep.js';
import PageHeader from '../components/PageHeader.js';
import TemplateSlider from '../components/TemplateSlider.js';
import OptionsFormat from '../components/OptionsPaperSize.js';
import OptionsColor from '../components/OptionsColor.js';
import OptionsTemplate from '../components/OptionsTemplate.js';
import TabbedPages from '../components/TabbedPages.js';
import TabbedPage from '../components/TabbedPage.js';
import EditName from '../components/EditName.js';
import Spinner from '../components/Spinner.js';
import DropZone from '../components/DropZone.js';
import ModalWrap from '../components/ModalWrap.js';
import { useCreateMailingV2, useGetColumnsV2, useUploadDataV2 } from '../clients/WizardClient.js';
import { useGetTemplatesV2, useGetTemplateTagsV2 } from '../clients/TemplatesClient.js';
import { useCalculatePriceV2 } from '../clients/MailingsClient.js';

import { content } from './CreateMailing.content.js';
import { config } from '../Config.js';
import OptionsSides from '../components/OptionsSides.js';

// animations for multi select
const animatedComponents = makeAnimated();

const CreateMailing = () => {
  const [wizardId, setWizardId] = useState(null);
  const [_errors, setErrors] = useState({});

  const [_currentStep, setCurrentStep] = useState(1);
  const [_isComplete, setIsComplete] = useState(false);

  const [_mailingName, setMailingName] = useState('Untitled');
  const [_isNamed, setIsNamed] = useState(false);
  const [_isNamedError, setIsNamedError] = useState(false);

  const [_isFormatSelected, setIsFormatSelected] = useState(false);
  const [_isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [_isDataMapped, setIsDataMapped] = useState(false);
  const [_isDataFullyMapped, setIsDataFullyMapped] = useState(false);

  const [_format, setFormat] = useState(false);
  const [_color, setColor] = useState(false);
  const [_side, setSide] = useState(false);
  const [_template, setTemplate] = useState(false);

  const [_modalNameShow, setModalNameShow] = useState(false);
  const [_modalConfirmShow, setModalConfirmShow] = useState(false);

  const [fileToUpload, setFileToUpload] = useState(null);

  const [mappings, setMappings] = useState([]);
  const [create, setCreate] = useState(false);

  const [_selectMap, setSelectMap] = useState(new Map());

  const uploadResponse = useUploadDataV2(wizardId, fileToUpload);
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    if (_format) {
      if (_format.id === 'letter') {
        setIsFormatSelected(_color && _side);
      } else {
        setIsFormatSelected(_color);
      }
    }
  }, [_format, _color, _side]);

  useEffect(() => {
    setWizardId(Math.random().toString(36).substr(2, 9));
  }, []);

  useEffect(() => {
    if (uploadResponse.success) {
      setItemCount(uploadResponse.data.itemCount);
    } else if (uploadResponse.error !== null) {
      setFileToUpload(null);
    }
  }, [uploadResponse]);

  const getColumnsResponse = useGetColumnsV2(wizardId, itemCount > 0);
  const uploadedDataColumns = getColumnsResponse.success
    ? getColumnsResponse.data.map((column) => {
      return { id: column.index, value: column.index, label: column.name };
    })
    : [];

  const templatesResponse = useGetTemplatesV2(1, 100, '', true);
  const templateTagsResponse = useGetTemplateTagsV2(_template.id);
  const createResponse = useCreateMailingV2(wizardId, _template.id, _mailingName, _color.id, mappings, _format.id, _side === false ? null : _side.id, create);

  useEffect(() => {
    if (createResponse.success) {
      setCurrentStep(numSteps);
    }
  }, [createResponse]);

  const calculatePriceResponse = useCalculatePriceV2(
    itemCount === 0 ? 1 : itemCount,
    wizardId,
    _template.pageCount,
    _color.id,
    _format.id,
    _side === false ? null : _side.id
  );
  const summaryPrice = calculatePriceResponse.success
    ? currencyFormat(
      calculatePriceResponse.data.lineItems
        .map((item) => {
          return (item.quantity / (itemCount === 0 ? 1 : itemCount)) * item.itemPrice;
        })
        .reduce((a, b) => a + b)
    )
    : '-';
  const summaryTotal = calculatePriceResponse.success && itemCount > 0 ? currencyFormat(calculatePriceResponse.data.exVat) : '-';
  const summaryQuantity = itemCount > 0 ? itemCount : '-';

  const { t_id: tID } = useParams();
  const numSteps = 6;

  const formats = config.Formats;

  // if tID - update selected template
  useEffect(() => {
    if (!tID || !templatesResponse.data.data || templatesResponse.data.data.length === 0) {
      return;
    }

    templatesResponse.data.data.forEach((item, i) => {
      if (item.id === tID) {
        setTemplate(item);
        setIsTemplateSelected(true);

        // set format
        formats.forEach((format, i) => {
          if (item.format === format.id) {
            setFormat(format);
          }
        });
      }
    });
  }, [templatesResponse]);

  const prevStep = (step) => {
    if (_isComplete) {
      return;
    }
    if (step - 1 > 0) {
      setCurrentStep(step - 1);
    }
  };

  const nextStep = (step) => {
    if (_isComplete) {
      return;
    }

    let errors = {};

    switch (step) {
      case 1:
        if (!_isFormatSelected) {
          // show error messages

          if (!_format) {
            errors.error_size = {
              message: content.Format.Size.Error,
            };
          }
          if (!_color) {
            errors.error_color = {
              message: content.Format.Colour.Error,
            };
          }
          if (!_side) {
            errors.error_side = {
              message: content.Format.Sides.Error,
            };
          }
          setErrors(errors);
          return;
        }
        break;
      case 2:
        if (!_isTemplateSelected) {
          // show error messages
          if (!_template) {
            errors.error_template = {
              message: content.TemplateSelect.Error,
            };
          }
          setErrors(errors);

          return;
        }
        break;
      case 3:
        if (!uploadResponse.success) {
          errors.error_file = {
            message: content.AddData.Error,
          };
          setErrors(errors);

          return;
        }
        break;
      case 4:
        if (!_isDataMapped) {
          errors.error_data = {
            message: content.MapData.Error,
          };
          setErrors(errors);
          return;
        } else if (!_isDataFullyMapped) {
          //show confirm modal
          setModalConfirmShow(true);
          return;
        }
        break;
      case 5:
        console.log('create');
        setCreate(true);
        return;
    }

    setErrors({});

    if (numSteps === step) {
      setIsComplete(setIsComplete);
    } else if (step + 1 <= numSteps) {
      setCurrentStep(step + 1);
    }
  };

  const doConfirm = (e, conf) => {
    setModalConfirmShow(false);
    if (conf) {
      setCurrentStep(5);
    }
  };

  const doReset = () => {
    // reset vars
    setIsComplete(false);
    setCurrentStep(1);

    setMailingName('Untitled');

    setIsFormatSelected(false);
    setIsTemplateSelected(false);

    setIsDataMapped(false);
    setIsDataFullyMapped(false);

    setFormat(false);
    setColor(false);
    setSide(false);
    setTemplate(false);

    setMappings([]);
    setCreate(false);
    setFileToUpload(null);

    setSelectMap(new Map());
  };

  /**
   * Event handlers
   */

  const onFormatSelect = (format) => {
    setFormat(format);

    if (_isTemplateSelected && format.id !== _template.format) {
      setIsTemplateSelected(false);
      setTemplate(false);
    }

    if(format.id !== 'letter'){
      setSide(false);
    }

    if (_errors.error_size) {
      let errors = _errors;
      errors.error_size = false;
      setErrors(errors);
    }
  };

  const onColorSelect = (color) => {
    setColor(color);

    if (_errors.error_color) {
      let errors = _errors;
      errors.error_color = false;
      setErrors(errors);
    }
  };

  const onSideSelect = (side) => {
    setSide(side);

    if (_errors.error_side) {
      let errors = _errors;
      errors.error_side = false;
      setErrors(errors);
    }
  }


  const onTemplateSelect = (template) => {
    setTemplate(template);
    setIsTemplateSelected(true);

    if (_errors.error_template) {
      let errors = _errors;
      errors.error_template = false;
      setErrors(errors);
    }
  };

  const onNameChange = (ev) => {
    setMailingName(ev.target.value);
  };

  const onKeyPress = (ev) => {
    if (ev.charCode === 13) {
      if (_mailingName !== '' && _mailingName !== 'Untitled') {
        setIsNamed(true);
      }
    }
  };

  const onNameClosed = () => {
    if (_mailingName === '') {
      setMailingName('Untitled');
    }
    setIsNamed(true);
  };

  const onNameChosen = () => {
    if (_mailingName !== '' && _mailingName !== 'Untitled') {
      setIsNamed(true);
      setIsNamedError(false);
    } else {
      setIsNamedError(true);
    }
  };

  const onNameUpdated = (name) => {
    setMailingName(name);
  };

  const uploadData = (file) => {
    if (file === undefined) {
      return;
    }
    if (_errors.error_file) {
      let errors = _errors;
      errors.error_file = false;
      setErrors(errors);
    }
    setFileToUpload(file);
  };

  const onClearFile = () => {
    setMappings([]);
    setFileToUpload(null);
    setSelectMap(new Map());
    setIsDataMapped(false);
    setIsDataFullyMapped(false);
  };

  const handleMapping = (selectedOption, id) => {
    // update select states
    let map = new Map(_selectMap);
    map.set(id, selectedOption);
    setSelectMap(map);

    // update mapping
    var existing = mappings;
    var current = existing.find((x) => x.id === id);
    if (current === undefined) {
      existing.push({ id, selectedOption });
    } else {
      var index = existing.indexOf(current);
      existing[index] = { id, selectedOption };
    }
    setMappings(existing);

    // check if all columns mapped
    const minAddressCount = 3;
    let usedAllMergeTags = true;
    let usedCount = 0;

    // check if 3 address fields used
    templateTagsResponse.data
      .filter((tag) => tag.type === 'Address')
      .forEach((item, i) => {
        const t = map.get(item.id);
        if (Array.isArray(t) && t.length > 0) {
          usedCount++;
        }
      });

    // check if all merge tags mapped
    templateTagsResponse.data
      .filter((tag) => tag.type === 'Custom')
      .forEach((item, i) => {
        const t = map.get(item.id);
        if (t === undefined || t === '' || (Array.isArray(t) && t.length === 0)) {
          usedAllMergeTags = false;
        }
      });

    // clear error
    if (usedCount >= minAddressCount && _errors.error_data) {
      let errors = _errors;
      errors.error_data = false;
      setErrors(errors);
    }

    // update state
    setIsDataMapped(usedCount >= minAddressCount);
    setIsDataFullyMapped(usedAllMergeTags);
  };

  const summaryFormat = _format ? _format.name : '-';
  const summaryColour = _color ? _color.name : '-';
  const summarySides = _side ? _side.name : '-';

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--create-a-mailing'>
        <div className='gp-section__inner'>
          <div className='flex'>
            <div className='col-6'>
              <div className='gp-section__heading'>
                <EditName name={_mailingName} callback={onNameUpdated} />
              </div>

              <CardStep
                stepNum={1}
                numSteps={numSteps}
                currentStep={_currentStep}
                onPrev={false}
                onNext={nextStep}
                nextEnabled={_isFormatSelected}
                title={content.Format.Title}
              >
                <h4>{content.Format.Size.Heading}</h4>
                <hr />
                <OptionsFormat sizes={formats} paperSize={_format} onSizeSelect={onFormatSelect} />
                {_errors.error_size && <p className='error-msg'>{_errors.error_size.message}</p>}

                <h4>{content.Format.Colour.Heading}</h4>
                <hr />
                <OptionsColor color={_color} onColorSelect={onColorSelect} />
                {_errors.error_color && <p className='error-msg'>{_errors.error_color.message}</p>}

                {_format.id === 'letter' && <>
                  <h4>{content.Format.Sides.Heading}</h4>
                  <hr />
                  <OptionsSides side={_side} onSidesSelect={onSideSelect} />
                  {_errors.error_side && <p className='error-msg'>{_errors.error_side.message}</p>}
                </>}
              </CardStep>

              <CardStep
                stepNum={2}
                numSteps={numSteps}
                currentStep={_currentStep}
                onPrev={prevStep}
                onNext={nextStep}
                nextEnabled={_isTemplateSelected}
                stepClass='card--templates'
                title={content.TemplateSelect.Title}
              >
                {templatesResponse.success &&
                  (templatesResponse.data.data.length > 0 ? (
                    templatesResponse.data.data.filter((t) => t.format.toLowerCase() === _format.id).length !== 0 ? (
                      <OptionsTemplate
                        templates={templatesResponse.data.data.filter((t) => t.format.toLowerCase() === _format.id)}
                        template={_template}
                        onTemplateSelect={onTemplateSelect}
                        isSlider={true}
                      />
                    ) : (
                      content.TemplateSelect.NoTemplatesInFormat
                    )
                  ) : (
                    content.TemplateSelect.NoTemplates
                  ))}

                {templatesResponse.error !== null && <p className='error-msg'>{content.TemplateSelect.Error}</p>}
              </CardStep>

              <CardStep
                stepNum={3}
                numSteps={numSteps}
                currentStep={_currentStep}
                onPrev={prevStep}
                onNext={nextStep}
                nextEnabled={itemCount > 0}
                stepClass='card--tabs'
                title={content.AddData.Title}
              >
                <TabbedPages tabs={[{ label: 'Upload', id: 'upload' }]}>
                  <TabbedPage key='upload' tab='upload'>
                    {uploadResponse.loading && <Spinner />}
                    {fileToUpload === null && !uploadResponse.success && (
                      <>
                        <DropZone callback={uploadData} acceptedTypes='.csv, .xsl, .xslx' />
                        {content.AddData.DownloadMessage}
                      </>
                    )}
                    {fileToUpload !== null && uploadResponse.success && (
                      <>
                        <p className='text-center'>
                          File uploaded: <strong>{fileToUpload.name}</strong>
                        </p>
                        <p className='text-center'>
                          <button className='link' onClick={onClearFile}>
                            Upload a different file
                          </button>
                        </p>
                      </>
                    )}

                    {_errors.error_file && <p className='error-msg text-center'>{_errors.error_file.message}</p>}
                    {uploadResponse.error !== null && (
                      <p className='error-msg text-center'>
                        {uploadResponse.error.message != null ? uploadResponse.error.message : 'Something went wrong'}
                      </p>
                    )}
                  </TabbedPage>
                </TabbedPages>
              </CardStep>

              <CardStep
                stepNum={4}
                numSteps={numSteps}
                currentStep={_currentStep}
                onPrev={prevStep}
                onNext={nextStep}
                nextEnabled={_isDataMapped}
                stepClass='card--mapping'
                title={content.MapData.Title}
              >
                {templateTagsResponse.success && (
                  <div className='flex'>
                    <div className={templateTagsResponse.data.filter((item) => item.type === 'Custom').length > 0 ? 'col-6' : 'col'}>
                      <div className='grey-box'>
                        <h4>{content.MapData.AddressTags.Heading}</h4>
                        {content.MapData.AddressTags.Desc}
                        <div className='form-group'>
                          {templateTagsResponse.data
                            .filter((item) => item.type === 'Address')
                            .map((item) => {
                              return (
                                <div key={item.id} className='form-row'>
                                  <label>{item.displayName}</label>
                                  <Select
                                    isMulti
                                    components={animatedComponents}
                                    options={uploadedDataColumns}
                                    value={_selectMap.get(item.id) ? _selectMap.get(item.id) : ''}
                                    onChange={(selectedOption) => handleMapping(selectedOption, item.id)}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {templateTagsResponse.data.filter((item) => item.type === 'Custom').length > 0 && (
                      <div className='col-6'>
                        <div className='grey-box'>
                          <h4>{content.MapData.TemplateTags.Heading}</h4>
                          {content.MapData.TemplateTags.Desc}
                          <div className='form-group'>
                            {templateTagsResponse.data
                              .filter((item) => item.type === 'Custom')
                              .map((item) => {
                                return (
                                  <div key={item.id} className='form-row'>
                                    <label>{item.displayName}</label>
                                    <Select
                                      isMulti
                                      components={animatedComponents}
                                      options={uploadedDataColumns}
                                      value={_selectMap.get(item.id) ? _selectMap.get(item.id) : ''}
                                      onChange={(selectedOption) => handleMapping(selectedOption, item.id)}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {_errors.error_data && <p className='error-msg'>{_errors.error_data.message}</p>}
              </CardStep>

              <CardStep
                stepNum={5}
                numSteps={numSteps}
                currentStep={_currentStep}
                onPrev={prevStep}
                onNext={nextStep}
                nextEnabled={!create}
                onNextLbl={
                  <>
                    Create <i className='icon-arrow-right'></i>
                  </>
                }
                title='Send for approval'
                stepClass='card--mailing-send'
              >
                <div className='content text-center'>
                  {!create && (
                    <>
                      <div className='icon'></div>
                      <div className='heading'>{content.Ready.Heading}</div>
                      <div className='copy'>{content.Ready.Content}</div>
                    </>
                  )}
                  {createResponse.loading && (
                    <>
                      <div className='icon'></div>
                      <div className='heading'>Creating...</div>
                      <Spinner />
                    </>
                  )}
                  {createResponse.error !== null && (
                    <>
                      <div className='icon'></div>
                      <div className='heading'>Something went wrong</div>
                      <p className='error-msg text-center'>There was a problem creating the mailing</p>
                    </>
                  )}
                </div>
              </CardStep>

              <CardStep
                stepNum={6}
                numSteps={numSteps}
                currentStep={_currentStep}
                onPrev={false}
                onNext={false}
                title=''
                stepClass='card--mailing-sent'
              >
                <div className='content text-center'>
                  <div className='logo'></div>
                  <div className='heading'>{content.Sent.Heading}</div>
                  <div className='copy'>{content.Sent.Content}</div>
                  <div className='btn-wrap'>
                    <Link to='/mailings' className='btn btn-outline'>
                      Track the progress
                    </Link>
                    <button className='btn btn-solid' onClick={doReset}>
                      Create another <i className='icon-refresh'></i>
                    </button>
                  </div>
                </div>
              </CardStep>
            </div>
            <div className='col-6'>
              <Card className='card--border card--summary' title='Summary'>
                <div className='flex flex--wrap'>
                  <div className='col-2'>
                    <div>Format</div>
                    <div>{summaryFormat}</div>
                  </div>
                  <div className='col-2'>
                    <div>Colour</div>
                    <div>{summaryColour}</div>
                  </div>
                  {
                    summaryFormat.toLowerCase() === 'letter' &&
                    <>
                      <div className='col-2'>
                        <div>Sides</div>
                        <div>{summarySides}</div>
                        </div>
                    </>
                  }
                  <div className='col-2'>
                    <div>Quantity</div>
                    <div>{summaryQuantity}</div>
                  </div>
                  <div className={`col-${summaryFormat.toLowerCase()=== 'letter' ?'2' : '3'}`}>
                    <div>Price</div>
                    <div>
                      {summaryPrice}
                      {_format && (
                        <>
                          <br />
                          <small>(price per {_format.name.toLowerCase()})</small>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={`col-${summaryFormat.toLowerCase()=== 'letter' ?'2' : '3'}`}>
                    <div>Total price</div>
                    <div>{summaryTotal}</div>
                  </div>
                </div>
              </Card>
              {_template && (
                <Card className='card--mailing-preview' title='Template'>
                  <TemplateSlider {..._template} />
                </Card>
              )}
            </div>
          </div>
        </div>
      </section>

      <ModalWrap heading='Choose a name' show={!_isNamed} onHide={() => onNameClosed()}>
        <div className='form-row'>
          <input
            type='text'
            id='input-mailing-name'
            placeholder='Enter mailing name'
            className={_isNamedError ? 'error' : ''}
            onChange={onNameChange}
            onKeyPress={onKeyPress}
          />
          {_isNamedError && <div className='error-msg'>Please choose a name</div>}
        </div>
        <div className='btn-wrap text-right'>
          <button className='btn btn-solid' onClick={(e) => onNameChosen()}>
            Continue
          </button>
        </div>
      </ModalWrap>

      <ModalWrap heading='Are you sure you want to continue?' show={_modalConfirmShow} onHide={() => setModalConfirmShow(false)}>
        <p>We noticed you have empty fields in your template data. Are you sure you want to continue?</p>
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={(e) => doConfirm(e, false)}>
            Cancel
          </button>
          <button className='btn btn-solid' onClick={(e) => doConfirm(e, true)}>
            Continue
          </button>
        </div>
      </ModalWrap>
    </>
  );
};

export default CreateMailing;
