const sides = [
  {
    id: 'simplex',
    name: 'Single sided'
  },
  {
    id: 'duplex',
    name: 'Double sided'
  },
];

const OptionsSides = ({ side, onSidesSelect }) => {
  return (
    <div className='options'>
        <div className='wrap'>
          {sides.map((item, i) => {
            const isSelected = side?.id === item.id ? ' is-selected' : '';
            return (
              <div key={item.id} className={'item' + isSelected}>
                <button className='item__inner' onClick={() => onSidesSelect(item)}>
                  <div className='meta'>
                    <div className='name'>{item.name}</div>
                  </div>
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default OptionsSides;
