import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import MenuItem from '../components/MenuItem.js';
import LinesButton from '../components/LinesButton';
import GlobalDataManager from '../GlobalDataManager.js';
import { config } from '../Config.js';

const Header = () => {
  const userData = GlobalDataManager.getInstance().getUserData();

  const [_currMenuItem, setCurrMenuItem] = useState(false);
  const [_menuOut, setMenuOut] = useState(false);

  const location = useLocation();

  const { isAuthenticated, logout } = useAuth0();

  // Highlight current menu item
  const menuItemSelected = (ev) => {
    setCurrMenuItem(ev.target);
    setMenuOut(false);
  };

  // Toggle mobile menu
  const onToggleMobileMenu = () => {
    setMenuOut(!_menuOut);
  };

  // update body class
  useEffect(() => {
    if (!_menuOut) {
      document.body.classList.remove('menu-out');
    } else {
      document.body.classList.add('menu-out');
    }
  }, [_menuOut]);

  const templatesSubMenu = [
    {
      id: 'view-templates',
      label: 'View templates',
      path: '/templates',
    },
    {
      id: 'create-template',
      label: 'Create a new template',
      path: '/create-template',
    },
  ];

  if (config.Toggles.EnableTemplateEditor) {
    templatesSubMenu.push({
      id: 'create-template-editor',
      label: 'Template editor',
      path: '/templates/editor',
    });
  }

  const menu = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      path: '/',
      icon: 'icon-home',
    },
    {
      id: 'mailings',
      label: 'Mailings',
      path: '',
      icon: 'icon-mailings',
      submenu: [
        {
          id: 'view-mailings',
          label: 'View mailings',
          path: '/mailings',
        },
        {
          id: 'create-mailing',
          label: 'Create a new mailing',
          path: '/create-mailing',
        },
      ],
    },
    {
      id: 'templates',
      label: 'Templates',
      path: '',
      icon: 'icon-templates',
      submenu: templatesSubMenu,
    },
    {
      id: 'manage-account',
      label: 'Manage Account',
      path: '/manage-account',
      icon: 'icon-user',
    },
    {
      id: 'documentation',
      label: 'Documentation',
      path: 'https://docs.postalzoom.com',
      icon: 'icon-help-circled-alt',
    },
  ];

  // check if can access integrations
  if (userData.subscription === 'Advanced') {
    menu.splice(3, 0, {
      id: 'integrations',
      label: 'Integrations',
      path: '',
      icon: 'icon-integrations',
      submenu: [
        {
          id: 'webhooks',
          label: 'Webhooks',
          path: '/webhooks',
        },
      ],
    });
  }

  const nav = (
    <ul className='menu-items'>
      {/* !isAuthenticated && (
      <li className="menu-item">
        <button className="menu-item__lbl" onClick={() => loginWithRedirect()}>
          <i className="icon-logout"></i>Log in
        </button>
      </li>
    ) */}
      {isAuthenticated && (
        <>
          {menu.map((item) => (
            <MenuItem key={item.id} location={location} item={item} onSelected={menuItemSelected} />
          ))}
          <li className='menu-item'>
            <button className='menu-item__lbl' onClick={() => logout({ returnTo: 'https://postalzoom.com' })}>
              <i className='icon-logout'></i>Log Out
            </button>
          </li>
        </>
      )}
    </ul>
  );

  return (
    <header className='main-header'>
      <div className='main-header__inner'>
        <div className='logo-wrap'>
          <Link to='/' className='logo' title='Home' rel='home'>
            <div className='icon'></div>
            <div className='name'>Postalzoom</div>
          </Link>
          <LinesButton active={_menuOut} onClick={onToggleMobileMenu} />
        </div>
        <div className='navbar'>
          <nav>{nav}</nav>
        </div>
      </div>
    </header>
  );
};
export default Header;
