import { useState, cloneElement } from 'react';
const TabbedPages = ({ tabs, children }) => {
  const [_currentTab, setCurrentTab] = useState(tabs.length > 0 ? tabs[0] : false);

  // make array if single object
  if (!Array.isArray(children)) {
    children = [children];
  }

  const onTabSelect = (tab) => {
    setCurrentTab(tab);
  };
  return (
    <div className={'tabbed-pages '}>
      <div className='tabbed-pages__inner'>
        <div className='tabs'>
          {tabs.map((item, i) => {
            const isActive = _currentTab.id === item.id ? ' active' : '';
            return (
              <button key={item.id} className={'tab' + isActive} onClick={() => onTabSelect(item)}>
                {item.label}
              </button>
            );
          })}
        </div>
        <div className='pages'>
          { children.map((item) => {
            const isActive = _currentTab && _currentTab.id === item.props.tab;
            const clone = cloneElement(item, { isActive: isActive });
            return clone;
          })}
        </div>
      </div>
    </div>
  );
};
export default TabbedPages;
