import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useReducer, useState } from 'react';

const useApi = (initialState, url, parseResponseAction, options = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [stateOptions, setStateOptions] = useState(null);

  if (stateOptions === null) {
    setStateOptions(options);
  } else if (options.body != null && options.body.entries !== undefined) {
    if (stateOptions.body === null) {
      setStateOptions(options);
    } else if (stateOptions.body.entries !== undefined) {
      // if .entries exists on the body, we're dealing with FormData, so look through and compare values
      for (let [key, value] of options.body.entries()) {
        if (stateOptions.body.get(key) !== value) {
          setStateOptions(options);
          break;
        }
      }
    }
  } else if (options.body !== stateOptions.body) {
    setStateOptions(options);
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true };
      case 'loaded':
        return {
          ...initialState,
          loading: false,
          data: action.response.data,
          status: action.response.status,
          statusText: action.response.statusText,
          success: true,
        };
      case 'error':
        return {
          ...initialState,
          loading: false,
          status: action.response.status,
          statusText: action.response.statusText,
          error: action.response.error,
          success: false,
        };
      default:
        return state;
    }
  }, initialState);

  async function fetchWithTimeout(url, options = {}) {
    const { timeout = 10000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });

    clearTimeout(id);

    return response;
  }

  useEffect(() => {
    // this allows to not make the request without having conditonal calls higher in the stack
    if (url === null || (stateOptions.method === 'POST' && stateOptions.body === null)) {
      return;
    }

    const makeRequest = async () => {
      var accessToken = await getAccessTokenSilently();
      dispatch({ type: 'loading' });

      try {
        const headers = { ...stateOptions.headers, ...{ Authorization: `Bearer ${accessToken}` } };
        const response = await fetchWithTimeout(url, {
          ...stateOptions,
          ...{ headers: headers },
        });

        if (response.status > 399) {
          const error = response.status === 400 ? await response.json() : 'Bad request';
          dispatch({
            type: 'error',
            response: {
              status: response.status,
              statusTest: response.statusText,
              error: error,
            },
          });
        } else {
          const data = await parseResponseAction(response);
          dispatch({
            type: 'loaded',
            response: {
              status: response.status,
              statusText: response.statusText,
              data: data,
            },
          });
        }
      } catch (error) {
        const createError = () => {
          if (error.name === 'AbortError') {
            return { message: 'Timeout talking to the server' };
          }

          if (error.message === 'Failed to fetch') {
            return { message: 'There was a problem talking to the server' };
          }

          return error;
        };

        dispatch({
          type: 'error',
          response: {
            error: createError(),
          },
        });
      }
    };

    makeRequest();
  }, [getAccessTokenSilently, url, parseResponseAction, stateOptions]); // options.method, options.timeout, options.headers, options.body, options.form]);

  return state;
};

export const useApiPostString = (url) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: '',
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.text();
  }, []);

  return useApi(initialState, url, parseResponse, { method: 'POST' });
};

export const useApiPostJson = (url, data) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.json();
  }, []);

  return useApi(initialState, url, parseResponse, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const useApiPostFormJson = (url, form, timeoutInMilliseconds) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.json();
  }, []);

  return useApi(initialState, url, parseResponse, {
    method: 'POST',
    body: form,
    timeout: timeoutInMilliseconds,
  });
};

export const useApiGetJson = (url, isArray) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: isArray ? [] : {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    switch (response.status) {
      case 200:
        return response.json();
      case 204:
        return {};
      default:
        throw new Error('unexpected status code');
    }
  }, []);

  return useApi(initialState, url, parseResponse);
};

export const useApiPatch = (url, data) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.text();
  }, []);

  return useApi(initialState, url, parseResponse, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    body: JSON.stringify(data),
  });
};

export const useApiGetBlob = (url) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: null,
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.blob();
  }, []);

  return useApi(initialState, url, parseResponse);
};

export const useApiDelete = (url) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: null,
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return;
  }, []);

  return useApi(initialState, url, parseResponse, {
    method: 'DELETE',
  });
};

export const useApiDeleteJson = (url, data) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return;
    //return response.json();
  }, []);

  return useApi(initialState, url, parseResponse, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
