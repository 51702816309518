export const content = {
    Heading: 'Mailings',
    ConfirmDeleteHeading: 'Confirm Delete',
    ConfirmDeleteText: <p>Are you sure you want to delete this mailing?</p>,
    DeleteThisMailingText: <><i className='icon-trash-empty'></i> Delete mailing</>,
    ConfirmDeleteAddText: <p>Are you sure you want to delete this address?</p>,
    NotificationDeleted: 'Mailing deleted',
    NotificationUpdated: 'Mailing updated',
    NotificationAddDeleted: 'Address deleted',
    NotificationError: 'Something went wrong. Please try again',
}
