import { config } from '../Config';
import { useApiDelete, useApiGetBlob, useApiGetJson, useApiPatch, useApiPostFormJson } from './client-hook';

const baseUri = config.Templates.BaseUri;

export const useGetTemplatesV2 = (page, quantity, formats, send) => {

  // TODO - this needs to accept array of filters as people can select multiple checkboxes
  formats = formats && formats.length === 1 ? formats[0] : '';

  const url = send == null || page == null || quantity == null ? null : `${baseUri}?page=${page}&qty=${quantity}&format=${formats}`;
  return useApiGetJson(url, false);
};

export const useChangeTemplateNameV2 = (templateId, name) => {
  const url = name === null ? null : `${baseUri}${templateId}`;
  return useApiPatch(url, [
    {
      op: 'replace',
      path: 'name',
      value: name,
    },
  ]);
};

export const useGetTemplateTagsV2 = (templateId) => {
  const url = templateId == null ? null : `${baseUri}${templateId}/tags`;
  return useApiGetJson(url, true);
};

export const useGetPreviewV2 = (templateId, index) => {
  const url = templateId == null || index == null ? null : `${baseUri}${templateId}/preview/${index}`;
  return useApiGetBlob(url);
};

export const useUploadTemplateV2 = (file, name, format) => {
  const url = file == null || name == null || format == null ? null : baseUri;
  const formData = () => {
    const form = new FormData();
    form.append('file', file);
    form.append('name', name);
    form.append('format', format);
    return form;
  };

  return useApiPostFormJson(url, url === null ? null : formData(), 20000);
};

export const useNameCheckV2 = (name, send) => {
  const url = send == null || name == null || name.length === '' || name === 'Untitled' ? null : `${baseUri}name-check/${name}`;
  return useApiGetJson(url);
}

export const useDeleteTemplateV2 = (templateId, send) => {
  const url = send ? `${baseUri}${templateId}` : null;
  return useApiDelete(url);
}
