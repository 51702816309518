const Alert = ({style = 'warning', title, actions, children}) => {
  return (
    <div className={"alert alert--" + style}>
      <div className="alert__inner">
				<div className="icon"></div>
				<div className="content">
					<div className="flex">
						<div className="col">
							{title && (
								<div className="alert-title">{title}</div>
							)}
							{children}
						</div>
						{actions && (
							<div className="col-action text-right">
								{actions}
							</div>
						)}
					</div>
				</div>
      </div>
    </div>
  );
}
export default Alert;
