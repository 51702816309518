import { useEffect } from 'react';
import PageHeader from '../../components/PageHeader';
import { createStore } from 'polotno/model/store';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { SidePanel, TextSection, BackgroundSection, UploadSection, LayersSection } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { DownloadButton } from 'polotno/toolbar/download-button';
import { config } from '../../Config';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

const store = createStore({ key: 'XbAOC6i9h8lue-NH3x2e' });
store.setUnit({
  unit: 'mm',
  dpi: 300,
});

store.setSize(2480, 3508, true);
store.setScale(0.5);

var addressPage = store.addPage();

addressPage.addElement({
  type: 'svg',
  src: '/images/300x300.svg',
  x: 200,
  y: 200,
  backgroundEnabled: true,
  backgroundColor: 'orange',
  backgroundOpacity: 0.5,
  placeholder: 'Address',
  width: 800,
  height: 400,
  selectable: false,
  alwaysOnTop: true,
  showInExport: false,
  draggable: false,
  contentEditable: false,
  removable: false,
});

const TemplateEditorPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sdk.canva.com/designbutton/v2/api.js';

    const initialise = async () => {
      var canvaApi = await window.Canva.DesignButton.initialize({
        apiKey: config.Canva.ButtonApiKey,
      });

      var button = document.getElementById('canvaButton');
      button.onclick = () => {
        canvaApi.createDesign({
          design: {
            type: 'A4Document',
          },
          onDesignPublish: (opts) => {
            console.log(opts);
          },
        });
      };
    };
    script.onload = initialise;

    document.body.appendChild(script);
  }, []);

  const ActionControls = ({ store }) => {
    return (
      <>
        <DownloadButton store={store} />

        <span id='canvaButton'>Design on Canva</span>
      </>
    );
  };

  return (
    <>
      <PageHeader pageTitle='Template Editor' />
      <section className='gp-section' style={{ height: '80vh' }}>
        <PolotnoContainer store={store}>
          <SidePanelWrap store={store}>
            <SidePanel store={store} sections={[TextSection, BackgroundSection, UploadSection, LayersSection]} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} components={{ ActionControls }} />
            <Workspace store={store} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </section>
    </>
  );
};

export default TemplateEditorPage;
