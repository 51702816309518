import { config } from '../Config';
import { useApiDelete, useApiGetJson, useApiPostJson } from './client-hook';

const baseUri = config.Webhooks.BaseUri;

export const useGetWebhooksV2 = (page, quantity) => {
  const url = page == null || quantity == null ? null : `${baseUri}?page=${page}&qty=${quantity}`;
  return useApiGetJson(url, false);
};

export const useCreateWebhookV2 = (name, templateId, colour, format, side, send) => {
  const url = send ? baseUri : null;
  var payload = { name, templateId, colour, format, side };
  return useApiPostJson(url, url === null ? null : payload);
};

export const useDeleteWebhookV2 = (webhookId, send) => {
  const url = send ? `${baseUri}${webhookId}` : null;
  return useApiDelete(url);
}