import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalDataManager from './GlobalDataManager.js';

import RequireAuth from './components/RequireAuth.js';
import ScrollToTop from './components/ScrollToTop.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Notifications from './components/Notifications.js';

import Loading from './components/Loading.js';
import Dashboard from './pages/Dashboard.js';
import Mailings from './pages/Mailings.js';
import Mailing from './pages/Mailing.js';
import CreateMailing from './pages/CreateMailing.js';
import Templates from './pages/Templates.js';
import TemplateUploaderFormat from './pages/TemplateUploaderFormat.js';
import Webhooks from './pages/Webhooks.js';
import ManageAccount from './pages/ManageAccount.js';
import Error404 from './pages/Error404.js';

import './App.css';
import LoginError from './pages/LoginError.js';

import mainContext from './context/context.js';
import TemplateEditorPage from './pages/TemplateEditor/TemplateEditorPage.js';

const App = () => {
  const { user, isLoading, loginWithRedirect } = useAuth0();

  const [_notifications, setNotifications] = useState([]);
  const [_notificationIndex, setNotificationIndex] = useState(1);

  if (window.location.pathname === '/signup') {
    const options = {
      authorizationParams: {
        screen_hint: 'signup'
      }
    };

    window.location.href = loginWithRedirect(options);
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  var error = new URLSearchParams(window.location.search).get('error');
  if (error === 'access_denied') {
    window.location.href = '/login-error';
    return null;
  }

  if (user) {
    const name = user.nickname.split('.');

    GlobalDataManager.getInstance().setUserData({
      username: user.nickname,
      firstName: name[0],
      lastName: name.length ? name[1] : '',
      picture: user.picture,
      subscription: user['https://postalzoom.com/subscription'],
      email: user.email,
      postalzoomAdmin: user['https://postalzoom.com/roles'].includes('PostalzoomAdmin'),
    });
  }

  // notifications
  const removeNotification = (id) => {
    let n = _notifications.filter((x) => x.id !== id);
    setNotifications([...n]);
  };

  const fadeOutNotification = (id) => {
    let n = _notifications;
    const index = n.findIndex((x) => x.id === id);
    let notification = n[index];
    notification.doFade = true;
    n[index] = notification;
    setNotifications([...n]);

    setTimeout(function () {
      removeNotification(id);
    }, 300);
  };

  const fadeInNotification = (id) => {
    let n = _notifications;
    const index = n.findIndex((x) => x.id === id);
    let notification = n[index];
    notification.doShow = true;
    n[index] = notification;
    setNotifications([...n]);
  };

  // contect object - accessible to all child nodes of mainContext
  const wContext = {
    notification: ({ message, type = 'default' }) => {
      // set unique id
      const id = _notificationIndex + 1;
      setNotificationIndex(id);

      // add to array
      let n = _notifications;
      n.push({ id: id, message, type, doShow: false, doFade: false });
      setNotifications(n);

      // fade in
      setTimeout(function () {
        fadeInNotification(id);
      }, 10);

      // fade out after 3 secs
      setTimeout(function () {
        fadeOutNotification(id);
      }, 3000);
    },
  };

  return (
    <mainContext.Provider value={wContext}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path='*' element={<Header />} />
        </Routes>
        <main className='main-container'>
          <Routes>
            <Route
              exact
              path='/'
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/mailing/:mailingId'
              element={
                <RequireAuth>
                  <Mailing />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/mailings'
              element={
                <RequireAuth>
                  <Mailings />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/create-mailing'
              element={
                <RequireAuth>
                  <CreateMailing />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/create-mailing/:t_id'
              element={
                <RequireAuth>
                  <CreateMailing />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/templates'
              element={
                <RequireAuth>
                  <Templates />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/create-template'
              element={
                <RequireAuth>
                  <TemplateUploaderFormat />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/webhooks'
              element={
                <RequireAuth>
                  <Webhooks />
                </RequireAuth>
              }
            />
            <Route
              exact
              path='/manage-account'
              element={
                <RequireAuth>
                  <ManageAccount />
                </RequireAuth>
              }
            />
            <Route exact path='/templates/editor' element={<TemplateEditorPage />} />
            <Route exact path='/login-error' element={<LoginError />} />
            <Route exact path='*' element={<Error404 />} />
          </Routes>
          <Footer />
        </main>
        <Notifications notifications={_notifications} />
      </Router>
    </mainContext.Provider>
  );
};

export default App;
