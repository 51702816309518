import { Link } from "react-router-dom"

export const content = {
    Heading: 'Mailings',
    Title: 'View or create mailings',
    CreateLink: <>Create new mailing <i className='icon-plus'></i></>,
    Empty: <>You have no mailings yet. <Link to={'/create-mailing/'}>Create a mailing</Link> now!</>,
    Error: 'Your mailings could not be loaded, please try again later.',
    LoadMore: 'Load More',
    Table:{
        Column0: 'Mailing name',
        Column1: 'Created',
        Column2: 'Last updated',
        Column3: 'Webhook',
        Column4: 'Status'
    },
    ConfirmDeleteHeading: 'Confirm Delete',
    ConfirmDeleteText: <p>Are you sure you want to delete this mailing?</p>,
    DeleteThisMailingText: <><i className='icon-trash-empty'></i> Delete mailing</>,
    NotificationDeleted: 'Mailing deleted',
}
