import { config } from '../Config';
import { useApiGetJson, useApiPostString } from './client-hook';

const baseUri = config.Accounts.BaseUri;

export const useUpgradeSubscriptionV2 = (send) => {
  const url = send ? `${baseUri}subscription/upgrade` : null;
  return useApiPostString(url);
};

export const useResetPasswordV2 = (send) => {
  const url = send ? `${baseUri}user/reset-password` : null;
  return useApiPostString(url);
};

export const useGetPaymentMethodV2 = (send) => {
  const url = send ? `${baseUri}paymentdetails` : null;
  return useApiGetJson(url, false);
};

export const useAddPaymentMethodV2 = (send) => {
  const url = send ? `${baseUri}payment-methods/add` : null;
  return useApiPostString(url);
}

export const useManageAccountV2 = (send) => {
  const url = send ? `${baseUri}manage-account` : null;
  return useApiPostString(url);
};

export const useGetSubscriptionDetailsV2 = (send) => {
  const uri = send ? `${baseUri}subscription/details` : null;
  return useApiGetJson(uri, false);
}

