import { config } from '../Config';
import { useApiGetJson, useApiPostFormJson, useApiPostJson } from './client-hook';

const baseUri = config.Wizard.BaseUri;

export const useUploadDataV2 = (wizardId, file) => {
  const url = file == null ? null : `${baseUri}data/upload`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('wizardId', wizardId);

  return useApiPostFormJson(url, url === null ? null : formData);
};

export const useGetColumnsV2 = (wizardId, send) => {
  const url = send === false ? null : `${baseUri}columns/${wizardId}`;
  return useApiGetJson(url, true);
};

export const useCreateMailingV2 = (wizardId, templateId, name, colour, mappings, format, side, send) => {
  var url = send ? `${baseUri}create` : null;
  const createPayload = () => {
    const maps = mappings
      .filter((x) => x.selectedOption.length > 0)
      .map((x) => ({
        tagId: x.id,
        map: x.selectedOption.map((y) => ({
          order: x.selectedOption.indexOf(y),
          headerIndex: y.value,
        })),
      }));

    return {
      id: wizardId,
      templateId,
      name,
      colour,
      format,
      side,
      mappings: maps,
    };
  };

  return useApiPostJson(url, send ? createPayload() : null);
};
