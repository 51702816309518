import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { date, timeSince, pillClass } from '../Utils.js';
import Spinner from '../components/Spinner.js';
import PageHeader from '../components/PageHeader.js';
import Card from '../components/Card.js';
import ModalWrap from '../components/ModalWrap.js';
import { useDeleteMailingV2, useGetMailingsV2 } from '../clients/MailingsClient.js';
import { content } from './Mailings.content.js';

import mainContext from '../context/context.js';

const Mailings = () => {

	const { notification } = useContext(mainContext);

  const perPage = 10;

  const [_page, setPage] = useState(1);
  const [loadedMailings, setLoadedMailings] = useState([]);

  const [_modalConfirmShow, setModalConfirmShow] = useState(false);
  const [_mailingToDelete, setMailingToDelete] = useState(null);
  const [_doDelete, setDoDelete] = useState(false);
  const [_deletedRows, setDeletedRows] = useState([]);

  const deleteMailingResponse = useDeleteMailingV2(_mailingToDelete, _doDelete);
  useEffect(() => {
    if (deleteMailingResponse.success) {
      // show confirmation message
      notification({ message: content.NotificationDeleted, type: 'positive' });

      if (_mailingToDelete) {
        let arr = _deletedRows;
        arr.push(_mailingToDelete);
        setDeletedRows(arr);
      }
      setModalConfirmShow(false);
      setMailingToDelete(null);
    }

    // reset delete method
    setDoDelete(false);
  }, [deleteMailingResponse]);

  const mailingsResponse = useGetMailingsV2(_page, perPage);
  useEffect(() => {
    if (mailingsResponse.success) {
      setLoadedMailings((existing) => [...existing, ...mailingsResponse.data.data]);
    }
  }, [mailingsResponse]);

  const loadMore = () => {
    setPage(_page + 1);
  };

  const onDeleteMailing = (tID) => {
    setMailingToDelete(tID);
    setModalConfirmShow(true);
  };

  const onConfirmDelete = (e, conf) => {
    if (!conf) {
      setModalConfirmShow(false);
      setMailingToDelete(null);
    } else {
      setDoDelete(true);
    }
  };

	const showWebhookCol = loadedMailings.filter(wh => wh.createdBy === 'webhook').length;

  return (
    <>
      <PageHeader pageTitle={content.Heading} />
      <section className='gp-section gp-section--mailings'>
        <div className='gp-section__inner'>
          <div className='gp-section__heading'>
            <div className='flex'>
              <div className='col-6'>
                <h2>{content.Title}</h2>
              </div>
              <div className='col-6 text-right'>
                <Link to='/create-mailing' className='btn btn-solid'>
                  {content.CreateLink}
                </Link>
              </div>
            </div>
          </div>

          <Card className='card--mailings'>
            {loadedMailings && loadedMailings.length > 0 && (
              <div className='table-responsive'>
                <table className='tbl-mailings has-border' style={{minWidth:'800px'}}>
                  <thead>
                    <tr>
                      <th style={{minWidth:'200px'}}>{content.Table.Column0}</th>
                      <th style={{minWidth:'150px'}}>{content.Table.Column1}</th>
                      <th style={{minWidth:'150px'}}>{content.Table.Column2}</th>
											{ showWebhookCol ?
											<th width='60' className='text-center'>{content.Table.Column3}</th> : null }
											<th style={{minWidth:'200px'}} className='text-center'>{content.Table.Column4}</th>
                      <th style={{minWidth:'80px'}}></th>
                      <th width='80'></th>
                    </tr>
                  </thead>
                  <tbody>
                    { loadedMailings.map((row, i) => {
                      if (_deletedRows.filter((id) => row.id === id).length) {
                        return;
                      }
                      const pClass = pillClass(row.status);
                      const d_created = Date.parse(row.created);
                      const d_updated = Date.parse(row.lastUpdated);
                      return (
                        <tr key={i}>
                          <td>{row.name}</td>
                          <td>{ row.createdBy === 'webhook' ? date(d_created) : timeSince(d_created)}</td>
                          <td>{ row.createdBy === 'webhook' ? date(d_created) : timeSince(d_updated)}</td>
													{ showWebhookCol ?
                          <td className='text-center'>
														{row.createdBy === 'webhook' && <span><i className='icon-ok'></i></span> }
                          </td> : null }
                          <td className='text-center'>
                            <span className={'pill ' + pClass}>{row.status}</span>
                          </td>
                          <td>
                            <Link to={'/mailing/' + row.id}>
                              View <i className='icon-angle-right'></i>
                            </Link>
                          </td>
                          <td>
                            {row.createdBy === 'wizard' && (row.status === 'Created' || row.status === 'Awaiting approval')? (
                              <button
                                className='btn btn-outline btn-action'
                                onClick={() => {
                                  onDeleteMailing(row.id);
                                }}
                              >
                                <i className='icon-trash-empty'></i>
                                <span className='visually-hidden'>Delete mailing</span>
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {mailingsResponse.error !== null && <p className='alert-msg text-center'>{content.Error}</p>}
            {mailingsResponse.loading && <Spinner />}
            {!mailingsResponse.loading && loadedMailings.length === 0 && mailingsResponse.error === null && (
              <p className='no-results text-center'>{content.Empty}</p>
            )}
          </Card>
          {mailingsResponse.data.hasMorePages ? (
            <div className='btn-wrap btn-wrap--centered'>
              <button
                className={mailingsResponse.loading ? 'btn btn-solid is-spinning' : 'btn btn-solid'}
                onClick={loadMore}
                disabled={mailingsResponse.data.loading}
              >
                {content.LoadMore}
              </button>
            </div>
          ) : null}
        </div>
      </section>

      <ModalWrap heading={content.ConfirmDeleteHeading} show={_modalConfirmShow} onHide={(e) => onConfirmDelete(e, false)}>
        {content.ConfirmDeleteText}
        {deleteMailingResponse.error !== null && <p className='alert-msg'>There was a problem deleting the mailing</p>}
        <div className='btn-wrap text-right'>
          <button className='btn btn-outline' onClick={(e) => onConfirmDelete(e, false)}>
            Cancel
          </button>
          <button className='btn btn-solid btn-delete' onClick={(e) => onConfirmDelete(e, true)}>
            Delete
          </button>
        </div>
      </ModalWrap>
    </>
  );
};

export default Mailings;
