import { useEffect, useState } from 'react';

const EditName = ({ name, callback, label = 'name' }) => {

	const [_name, setName] = useState(name);
	const [_isEditing, setIsEditing] = useState(false);

	const onEditMode = () => {
		setIsEditing(true);
	}

	const onChange = (ev) => {
		setName(ev.target.value);
	}

	const onSave = () => {
		setIsEditing(false);
		callback(_name);
	}

	useEffect(() => {
		setName(name);
	}, [name]);

	if (!_isEditing) {
		return (
			<div className="edit-name">
				<h1>{_name}</h1>
				<button className="link" onClick={onEditMode}>Edit {label}</button>
			</div>
		)
	}
	else {
		return (
			<div className="edit-name">
				<h1><input type="text" value={_name} onChange={onChange} /></h1>
				<button className="link" onClick={onSave}>Save {label}</button>
			</div>
		)
	}
}
export default EditName;
