import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import GlobalDataManager from '../GlobalDataManager.js';

const PageHeader = ({ pageTitle }) => {
  const userData = GlobalDataManager.getInstance().getUserData();

  const { logout } = useAuth0();

  const [_userMenuOpen, setUserMenuOpen] = useState(false);
  const [_userMenuClass, setUserMenuClass] = useState('');

  const [_helpMenuOpen, setHelpMenuOpen] = useState(false);
  const [_helpMenuClass, setHelpMenuClass] = useState('');

  const [_notificationsOpen, setNotificationsOpen] = useState(false);
  const [_notificationsClass, setNotificationsClass] = useState('');

  useEffect(() => {
    setUserMenuClass(_userMenuOpen ? ' show' : '');
    setHelpMenuClass(_helpMenuOpen ? ' show' : '');
    setNotificationsClass(_notificationsOpen ? ' show' : '');
  }, [_userMenuOpen, _helpMenuOpen, _notificationsOpen]);

  const toggleOpen = (ev) => {
    ev.preventDefault();
    setUserMenuOpen(!_userMenuOpen);

    setHelpMenuOpen(false);
    setNotificationsOpen(false);
  };

  const toggleOpenHelp = (ev) => {
    ev.preventDefault();
    setHelpMenuOpen(!_helpMenuOpen);

    setUserMenuOpen(false);
    setNotificationsOpen(false);
  };

  return (
    <div className='page-header'>
      <div className='flex'>
        <div className='col'>
          <div className='page-title'>{pageTitle}</div>
        </div>
        <div className='col text-right'>
          {userData.postalzoomAdmin && (
            <div className='dash-btns' style={{ color: 'red' }}>
              Logged in as Postalzoom admin
            </div>
          )}
          <div className='dash-btns'>
            <div className='btn-help-wrap'>
              <button className='btn-help' aria-expanded={_userMenuOpen ? 'true' : 'false'} onClick={toggleOpenHelp}>
                <i className='icon-help-circled-alt'></i>
                <span className='visually-hidden'>Show help</span>
              </button>
              <div className={'dropdown-menu dropdown-menu--help' + _helpMenuClass}>
                <div className='dropdown-header'>
                  <span className='menu-title'>Support</span>
                </div>
                <a target='_blank' href='https://docs.postalzoom.com' className='dropdown-item'>
                  <span>Get help for this page</span>
                  <i className='icon-angle-right'></i>
                </a>
              </div>
            </div>
          </div>
          <div className='dash-user'>
            <div className='header-user'>
              <button className='menu-toggle' tabIndex='0' aria-expanded={_userMenuOpen ? 'true' : 'false'} onClick={toggleOpen}>
                <div className='user-name'>
                  <span>{userData.firstName} {userData.lastName}</span>
                </div>
                <div className='user-image bevel' style={{ backgroundImage: `url("${userData.picture}")` }}></div>
              </button>

              <div className={'dropdown-menu' + _userMenuClass}>
                <div className='dropdown-header'>
                  <div className='media d-user'>
                    <div className='user-image bevel' style={{ backgroundImage: `url("${userData.picture}")` }}></div>
                    <div className='media-body'>
                      <h5>{userData.username}</h5>
                      <span>{userData.email}</span>
                    </div>
                  </div>
                </div>

                <Link to='/manage-account' className='dropdown-item'>
                  <i className='icon-user'></i> Manage account
                </Link>
                <button className='dropdown-item' onClick={() => logout({ returnTo: window.location.origin })}>
                  <i className='icon-logout'></i> Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageHeader;
