import Spinner from './Spinner.js';
const Loading = () => {
  return (
    <>
      <section className="gp-section gp-section--loading">
        <div className="gp-section__inner">
          <Spinner />
        </div>
      </section>
    </>
  );
}

export default Loading;
