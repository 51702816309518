import { useEffect, useState } from 'react';
import Spinner from '../components/Spinner.js';
import PageHeader from '../components/PageHeader.js';
import Card from '../components/Card.js';
import Alert from '../components/Alert.js';
import {
  useAddPaymentMethodV2,
  useGetPaymentMethodV2,
  useManageAccountV2,
  useResetPasswordV2,
  useUpgradeSubscriptionV2,
  useGetSubscriptionDetailsV2,
} from '../clients/AccountsClient.js';
import GlobalDataManager from '../GlobalDataManager.js';
import { content } from './ManageAccount.content.js';

const ManageAccount = () => {
  const userData = GlobalDataManager.getInstance().getUserData();

  const [manageAccount, setManageAccount] = useState(false);
  const manageAccountRedirect = useManageAccountV2(manageAccount);
  useEffect(() => {
    if (manageAccountRedirect.success) {
      window.location.href = manageAccountRedirect.data;
    }
  }, [manageAccountRedirect]);

  const [upgradeSubscription, setUpgradeSubscription] = useState(false);
  const upgradeSubscriptionRedirect = useUpgradeSubscriptionV2(upgradeSubscription);
  useEffect(() => {
    if (upgradeSubscriptionRedirect.success) {
      window.location.href = upgradeSubscriptionRedirect.data;
    }
  }, [upgradeSubscriptionRedirect]);

  const [resetPassword, setResetPassword] = useState(false);
  const resetPasswordRedirect = useResetPasswordV2(resetPassword);

  const paymentMethodResponse = useGetPaymentMethodV2(true);

  const subscriptionMethodResponse = useGetSubscriptionDetailsV2(userData.subscription === 'Advanced');

  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const addPaymentMethodRedirect = useAddPaymentMethodV2(addPaymentMethod);
  useEffect(() => {
    if (addPaymentMethodRedirect.success) {
      window.location.href = addPaymentMethodRedirect.data;
    }
  }, [addPaymentMethodRedirect]);

  return (
    <>
      <PageHeader pageTitle='Manage Account' />
      <section className='gp-section gp-section--manage-account'>
        <div className='gp-section__inner'>
          <Card
            className='card--border card--upgrade-plan'
            title='Subscription Plan'
            button={
              userData.subscription === undefined ? null : userData.subscription === 'PayAsYouGo' ? (
                <button className='btn btn-outline' onClick={() => setUpgradeSubscription(true)}>
                  Upgrade plan
                </button>
              ) : (
                <button className='btn btn-outline' onClick={() => setManageAccount(true)}>
                  Manage subscription
                </button>
              )
            }
          >
            {userData.subscription === 'PayAsYouGo' && (
              <>
                <p>Currently you're on our Pay as you go plan, with no monthly charge</p>
                <p>Upgrade to Standard to unlock extra features</p>
              </>
            )}

            {userData.subscription === undefined && (
              <div className='info-box info-box--warning'>
                <p>Sorry - there was a problem checking your subscription</p>
                <p>If this contnues to happen, try logging out and back in</p>
              </div>
            )}

            {subscriptionMethodResponse.loading && <Spinner />}
            {subscriptionMethodResponse.error !== null && (
              <>
                <div className='info-box info-box--warning'>
                  <p>Sorry - there was a problem retrieving your subscription data</p>
                </div>
              </>
            )}
            {!subscriptionMethodResponse.loading && subscriptionMethodResponse.success && subscriptionMethodResponse.status === 200 ? (
              <>
                <table className='table-subscription has-border'>
                  <tbody>
                    <tr>
                      <td>Current Plan</td>
                      <td>
                        <strong>
                          {userData.subscription === 'Advanced'
                            ? 'Advanced'
                            : userData.subscription === 'PayAsYouGo'
                              ? 'Pay as you go'
                              : 'No active plan.'}
                        </strong>
                        <br />
                        {userData.subscription === 'Advanced' && !subscriptionMethodResponse.data.isCanceled && (
                          <>
                            <p>You are currently on our Advanced plan</p>
                          </>
                        )}
                      </td>
                    </tr>
                    {subscriptionMethodResponse.data.created && (
                      <tr>
                        <td>Start Date</td>
                        <td>
                          <strong>{subscriptionMethodResponse.data.created}</strong>
                        </td>
                      </tr>
                    )}
                    {subscriptionMethodResponse.data.expires && (
                      <tr>
                        <td>Expires</td>
                        <td>
                          <strong>{subscriptionMethodResponse.data.expires}</strong>
                        </td>
                      </tr>
                    )}
                    {subscriptionMethodResponse.data.isTrial ||
                      (subscriptionMethodResponse.data.isCanceled && (
                        <tr>
                          <td>Status</td>
                          <td>
                            {subscriptionMethodResponse.data.isCanceled && !subscriptionMethodResponse.data.isTrial && (
                              <>
                                <p>
                                  <strong className='warning'>Cancelled</strong>
                                </p>
                                {content.Messages.Cancelled}
                              </>
                            )}
                            {subscriptionMethodResponse.data.isTrial && !subscriptionMethodResponse.data.isCanceled && (
                              <>
                                <p>
                                  <strong>Trial</strong>
                                </p>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ) : null}
          </Card>

          <Card
            className='card--border card--update-payment'
            title='Payment Method'
            button={
              paymentMethodResponse.success && paymentMethodResponse.status === 204 ? (
                <button className='btn btn-outline' onClick={() => setAddPaymentMethod(true)}>
                  Add Payment Method
                </button>
              ) : (
                <button className='btn btn-outline' onClick={() => setManageAccount(true)}>
                  Update Payment Method
                </button>
              )
            }
          >
            {paymentMethodResponse.loading && <Spinner />}
            {paymentMethodResponse.error !== null && (
              <>
                <div className='info-box info-box--warning'>
                  <p>Sorry - there was a problem retrieving your payment data</p>
                </div>
              </>
            )}
            {!paymentMethodResponse.loading && paymentMethodResponse.success && paymentMethodResponse.status === 200 ? (
              <>
                <p>
                  <span className='uppercase'>{paymentMethodResponse.data.brand}</span> ending in{' '}
                  {paymentMethodResponse.data.lastFourDigits}
                </p>
                <p>Expires {paymentMethodResponse.data.expiry}</p>
              </>
            ) : null}

            {!paymentMethodResponse.loading &&
              paymentMethodResponse.error === null &&
              !(paymentMethodResponse.success && paymentMethodResponse.status === 200) ? (
              <>
                <p>You haven't added a payment method yet</p>
                <p>Click the button to add one</p>
              </>
            ) : null}
          </Card>

          {!paymentMethodResponse.loading &&
            paymentMethodResponse.error === null &&
            !(paymentMethodResponse.success && paymentMethodResponse.status === 200) ? (
            null
          ) : <Card className='card--border'
            title='Invoices'
            button={
              <button className='btn btn-outline' onClick={() => setManageAccount(true)}>
                View Invoices
              </button>
            }
          >
            Invoices can be viewed and downloaded from the billing section of your account
          </Card>}



          <Card className='card--border card--update-prefs' title='Reset Password'>
            {resetPasswordRedirect.success ? (
              <Alert title='Reset password requested' style='positive'>
                <p>A password reset email has been sent. Please check your inbox.</p>
              </Alert>
            ) : (
              <>
                <p>Use the button below to request a new password</p>
                <div className='btn-wrap'>
                  <button
                    className={resetPasswordRedirect.loading ? 'btn btn-solid is-spinning' : 'btn btn-solid'}
                    disabled={resetPasswordRedirect.loading}
                    onClick={() => setResetPassword(true)}
                  >
                    {resetPasswordRedirect.loading ? 'Sending' : 'Request a password reset email'}
                  </button>
                </div>
              </>
            )}
          </Card>
        </div>
      </section>
    </>
  );
};

export default ManageAccount;
