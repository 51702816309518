import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import ProofPanel from '../components/ProofPanel.js';

// https://swiperjs.com/

const ProofSlider = ({ id, pageCount }) => {
  if (pageCount === 0) {
    return (
      <div className='pending-preview'>
        <div className='pending-preview__inner'>
          <div className='image'></div>
          <div className='msg'>
            Your preview is being created.
            <br />
            Check back soon.
          </div>
        </div>
      </div>
    );
  }

  let pages = [];
  for (var i = 1; i <= pageCount; i++) {
    pages.push({
      id: id,
      index: i,
    });
  }
  return (
    <div className='template-slider'>
      <div className='template-slider__inner'>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          className='slider'
        >
          {pages.map((item, i) => {
            return (
              <SwiperSlide key={item.index}>
                <ProofPanel {...item} pageIndex={item.index} colClass='' />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
export default ProofSlider;
