import PageHeader from '../components/PageHeader.js';

const LoginError = () => {
  return (
    <>
      <PageHeader />
      <section className="gp-section gp-section--error404">
        <div className="gp-section__inner">
          <h1>Sorry</h1>
          <p>We couldn't log you in</p>
          <p>
            <a href="/">Click here to try again</a>
          </p>
        </div>
      </section>
    </>
  );
}

export default LoginError;
