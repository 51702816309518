const Panel = ({ children }) => {
    return (
      <div className="panel">
        <div className="panel__inner">
          { children }
        </div>
      </div>
    );
}
export default Panel;
