import { Link } from 'react-router-dom';
export const content = {
	Heading: 'Create a mailing',
	Format: {
		Title: 'Format',
		Size: {
			Heading: 'Choose a format',
			Error: 'Please choose a format'
		},
		Colour: {
			Heading: 'Colour',
			Error: 'Please choose a colour'
		},
		Sides: {
			Heading: 'Sides',
			Error: 'Please choose a side'
		}
	},
	TemplateSelect: {
		Title: 'Select your template',
		Error: 'There was a problem retrieving the templates',
		NoTemplates: <p className="text-center">
			You have no templates. <br />Please <Link to="/create-template">upload</Link> or <a href='/templates/postalzoom-sample-letter.docx' download>download</a> our template
		</p>,
		NoTemplatesInFormat: <p className="text-center">
			You have no templates in this format. <br />Please choose a different format, <Link to="/create-template">upload</Link>, or <a href='/templates/postalzoom-sample-letter.docx' download>download</a> our template
		</p>,
	},
	AddData: {
		Title: 'Add your data',
		Error: 'Please upload a CSV file',
		DownloadMessage: <p className='text-center'>
			Download a{' '}
			<a href='/sample.csv' download>
				sample CSV
			</a>{' '}
			template to see an example of the format required.
		</p>
	},
	MapData: {
		Title: 'Map your data',
		Error: 'Please map at least 3 address fields to continue',
		AddressTags: {
			Heading:'Address Data',
			Desc:<>
				<p>
					Add your address information by assigning them in the dropdowns below.
				</p>
			</>
		},
		TemplateTags: {
			Heading:'Template Data',
			Desc:<>
				<p>
					Assign your merge data to the template tags from your data.
				</p>
			</>
		}
	},
	Ready: {
		Heading:'You’re all set to create!',
		Content: <p>Click create and we'll generate the preview</p>
	},
	Sent: {
		Heading:'Created!',
		Content: <p>Our team are hard at work preparing your mailing and generating your preview.</p>
	}
}
