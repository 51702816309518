export const content = {
    Heading: 'Webhooks',
    Title: 'Webhooks',
    CreateButton: <>Create webhook <i className='icon-plus'></i></>,
    Error: 'Webhooks could not be loaded, please try again later.',
    ErrorDelete: 'There was a problem deleting this webhook. Please try again later.',
    Empty: 'You have not set up any integrations.',
    EmptyButton: 'Create a webhook',
    LoadMore: 'Load More',
    Table: {
        Column0: <><span className='hide-mobile'>Webhook </span>name</>,
        Column1: 'Url',
        Column2: 'Example JSON'
    },
    CopyButton: 'Copy URL',
    ViewButton: 'View',
    View:{
        Heading: 'Example JSON'
    },
    Create:{
        Heading: 'Create Webhook',
        NameField: 'Name',
        TemplateField: 'Template',
        TemplateSelect: '- Select a template -',
        ColourField: 'Colour option',
        SizeField: 'Size option',
        SideField: 'Side options'
    },
    Modal: {
      Title: 'Are you sure you want to delete this webhook?',
      Content: <ul>
        <li>The webhook will be permanently deleted. This cannot be undone.</li>
        <li>All mailings sent to the webhook today will also be deleted.</li>
      </ul>
    },
    Notification: {
      Deleted: 'Webhook has been deleted',
      Error: 'There was a problem deleting your webhook',
      Created: 'Webhook created'
    }

}
