/* Formats */
// currency
export const currencyFormat = (num) => {
  num = num / 100; // convert to pounds
  return '£' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};


// date
export const date = (str) => {

	const date = new Date(str);

	return (
    `${('0' + date.getDate()).slice(-2)}/` +
    `${('0' + (date.getMonth() + 1)).slice(-2)}/` +
    `${('' + date.getFullYear()).slice(2)} `
  )
}
export const dateTime = (str) => {

	const date = new Date(str);

	return (
    `${('0' + date.getDate()).slice(-2)}/` +
    `${('0' + (date.getMonth() + 1)).slice(-2)}/` +
    `${('' + date.getFullYear()).slice(2)} ` +
    `${('0' + date.getHours()).slice(-2)}:` +
    `${('0' + date.getMinutes()).slice(-2)}`
  )
}

export const timeSince = (d) => {
  const date = new Date(d);
  const today = new Date();
  const dif = today.getTime() - date.getTime();

  if (dif <= 3600000) {
    const minutes = Math.floor(dif / (60 * 1000));

	if(minutes === 0){
		return 'Just now'
	}

    //return (minutes > 1) ? minutes + ' mins ago' : minutes + ' min ago';
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  }
  // hours
  else if (dif <= 43200000) {
    const hours = Math.floor(dif / (60 * 60 * 1000));
    //return (hours > 1) ? hours + ' hours ago' : hours + ' hour ago';
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  // days
  else if (dif <= 172800000) {
    const days = Math.round(dif / (60 * 60 * 24 * 1000));
    //return (days > 1) ? days + ' days ago' : days + ' day ago';
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }

  return (
    `${('0' + date.getDate()).slice(-2)}/` +
    `${('0' + (date.getMonth() + 1)).slice(-2)}/` +
    `${('' + date.getFullYear()).slice(2)} ` +
    `${('0' + date.getHours()).slice(-2)}:` +
    `${('0' + date.getMinutes()).slice(-2)}`
  );
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};


// pill Style
export const pillClass = (status) => {
  switch (status) {
    case 'Awaiting approval':
      return 'pending';
    case 'Approved':
      return 'complete';
    case 'Sent':
      return 'success';
      default:
        return '';
  }
}
