import { useEffect, useState } from 'react';

const Notifications = ({notifications = []}) => {

	const [_notifications, setNotifications] = useState(notifications);

  useEffect(() => {
    setNotifications(notifications);
  }, [notifications]);

	return <div className='notifications'>
		{_notifications.map((item) => {
			const show = (item.doShow) ? ' show' : '';
			const fade = (item.doFade) ? ' fade' : '';
			return (
				<div key={item.id} className={'notification notification--'+item.type+show+fade}>
					<div className="notification__inner">{item.message}</div>
				</div>
			);
		})}
	</div>
}
export default Notifications;
