import { config } from '../Config';
import { useApiDelete, useApiDeleteJson, useApiGetBlob, useApiGetJson, useApiPatch, useApiPostString } from './client-hook';

const baseUri = config.Mailings.BaseUri;

export const useGetMailingsV2 = (page, quantity) => {
  return useApiGetJson(`${baseUri}?page=${page}&qty=${quantity}`, false);
};

export const useChangeMailingNameV2 = (id, name) => {
  const url = name === null ? null : `${baseUri}${id}`;
  return useApiPatch(url, [
    {
      op: 'replace',
      path: 'name',
      value: name,
    },
  ]);
};

export const useGetAddressesV2 = (id, page, quantity) => {
  const url = id === null || page === null || quantity === null ? null : `${baseUri}${id}/addresses?page=${page}&qty=${quantity}`;
  return useApiGetJson(url, false);
};

export const useDeleteAddressV2 = (id, address, send) => {
  const url = address !== null && send !== null && send !== false ? `${baseUri}${id}/addresses` : null;
  return useApiDeleteJson(url, [
    {
      batchId: address ? address.batchId : null,
      rowNumber: address ? address.row : null,
    },
  ]);
};

export const useGetProofV2 = (id, index) => {
  const url = id !== null && index !== null ? `${baseUri}${id}/proof/${index}` : null;
  return useApiGetBlob(url);
};

export const useGetPricesV2 = (mailingId) => {
  const url = mailingId === null ? null : `${baseUri}${mailingId}/prices`;
  return useApiGetJson(url);
};

export const useGetMailingV2 = (mailingId) => {
  const url = mailingId === null ? null : `${baseUri}${mailingId}`;
  return useApiGetJson(url, false);
};

export const useApproveMailingV2 = (mailingId, send) => {
  const url = send ? `${baseUri}${mailingId}/approve` : null;
  return useApiPostString(url);
};

export const useCalculatePriceV2 = (qty, wizardId, pages, colourId, format, side) => {
  const isColour = colourId === 'colour';
  const url =
    qty == null || pages == null || wizardId == null || colourId == null || format == null || (format === 'letter' && side === null)
      ? null
      : `${baseUri}price?qty=${qty}&pages=${pages}&isColour=${isColour}&format=${format}&wizardId=${wizardId}&side=${side}`;
  return useApiGetJson(url, false);
};

export const useDeleteMailingV2 = (mailingId, send) => {
  const url = send ? `${baseUri}${mailingId}` : null;
  return useApiDelete(url);
};
