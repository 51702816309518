import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
// https://react-bootstrap.github.io/components/modal/

const ModalWrap = ({ heading, show, onHide, children, staticBG = false }) => {


  const [_show, setShow] = useState(show);

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop={(staticBG) ? 'static' : true }
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { children }
      </Modal.Body>
    </Modal>
  );
}
export default ModalWrap;


// <Modal.Footer>
//   <button onClick={onHide}>Close</button>
// </Modal.Footer>
