import { useGetPreviewV2 } from '../clients/TemplatesClient';
import Spinner from '../components/Spinner.js';
import processingImg from '../images/processing.jpg';

const TemplatePanel = ({ id, name, size, pageIndex = 1, onClick, colClass = 'col-20' }) => {
  const previewResponse = useGetPreviewV2(id, pageIndex);

  const handleClick = (id) => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div className={'template-panel ' + colClass}>
      <button className='template-panel__inner' onClick={() => handleClick(id)}>
        <div className='template-icon'>
          <div className='image-wrap'>
            <div className='image'>
              {previewResponse.success && <img src={URL.createObjectURL(previewResponse.data)} width='100%' />}
              {previewResponse.loading && <Spinner />}
              {previewResponse.status === 404 && <img src={processingImg} width='100%' />}
            </div>
          </div>
        </div>
        <div className='template-meta'>
          <div className='template-name'>{name}</div>
          <div className='template-size'>{size}</div>
        </div>
      </button>
    </div>
  );
};
export default TemplatePanel;
