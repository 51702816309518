import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import { useGetPreview } from '../clients/TemplatesClient';
import TemplatePanel from '../components/TemplatePanel.js';

const OptionsTemplate = ({ templates, template, onTemplateSelect, isSlider = false }) => {

  return (
    <div className="options-template">
      <div className="options-template__inner">
        { isSlider && (
          <Swiper
              modules={[Pagination, Navigation]}
              pagination={{ clickable: true, dynamicBullets: true }}
              slidesPerView={3}
              navigation
              className="templates-slider"
            >
            {templates.map((item, i) => {
              const isSelected = (template.id === item.id) ? ' is-selected' : '';
              return (
                <SwiperSlide key={item.id}>
                  <TemplatePanel
                    {...item}
                    onClick={() => onTemplateSelect(item)}
                    colClass={isSelected}
                  />
                </SwiperSlide>
              )
            })}
            </Swiper>
          )
        }
        { !isSlider &&
          <div className="templates">
          {templates.map((item, i) => {
            const isSelected = (template.id === item.id) ? ' is-selected' : '';
            return (
              <TemplatePanel
                key={item.id}
                {...item}
                onClick={() => onTemplateSelect(item)}
                colClass={isSelected}
              />
            )
          })}
  	      </div>
        }
      </div>
    </div>
  );
}
export default OptionsTemplate;
