import PageHeader from '../components/PageHeader.js';

const Error404 = () => {
  return (
    <>
      <PageHeader />
      <section className="gp-section gp-section--error404">
        <div className="gp-section__inner">
          <h1>404</h1>
			    <p>It looks like nothing was found at this location.</p>
        </div>
      </section>
    </>
  );
}

export default Error404;
